import { LOCALES } from '../locales'

export default {
    [LOCALES.UKRAINIAN]: {
        "issues": {
            "browser_header": "Легко забронюйте зустріч онлайн.",
            "what_is_cleverq": "Що таке cleverQ?",
            "additional_issues": "Виберіть додаткові питання"
        },
        "app": {
            "header": "Що таке cleverQ?",
            "important": "Поточна інформація"
        },
        "persons": {
            "header": "Будь ласка, вкажіть, скільки людей ви хочете записатися на прийом",
            "information": "<b style={{ color: 'red' }}>ЗВЕРНІТЬ УВАГУ: </b> Записатися на прийом можна тільки на одну зустріч. Навіть якщо вам потрібно подати заяву та подати заявку на отримання дозволу на повторний в'їзд. Хоча записатися можна тільки на одну зустріч, про вас подбають в обох питаннях.",
            "continue": "Продовжити вибір дати",
            "remove": "Видалити",
            "to_much_time": "Кількість питань і процедур перевищує дозволений час призначення. Будь ласка, зменшіть свій вибір."
        },
        "datepicker": {
            "header": "PБудь ласка, виберіть бажаний день.",
            "mon": "Пн",
            "tue": "Вт",
            "wed": "Ср",
            "thu": "Чт",
            "fri": "Пт",
            "sat": "Сб",
            "sun": "Нд",
            "no_appointments": "На жаль, всі дати на ваш вибір в даний час повністю заброньовані. Будь ласка, спробуйте пізніше",
            "error": "Виникла проблема із запитом на зустріч, будь ласка, повторіть спробу пізніше."
        },
        "slotpicker": {
            "header": "Будь ласка, виберіть потрібний час",
            "no_appointments": "На жаль, в цей день немає доступних зустрічей.",
            "legend_green": "Доступні зустрічi",
            "legend_yellow": "Залишилося лише кілька зустрічей",
            "legend_grey": "Немає доступних зустрічей",
            "timeout_reached": "З'єднання з сервером втрачено, спробуйте ще раз."
        },
        "dataforms": {
            "ident_number": "Iдентифікаційний номер",
            "mandatory_fields": "Обов'язкові поля",
            "header": "Будь ласка, введіть свої персональні дані.",
            "data_delete": "Ваші дані будуть автоматично видалені з cleverQ після вашої зустрічі.",
            "ms": "Пані",
            "mr": "Пан",
            "salutation": "Звертання",
            "company": "Назва компанії",
            "given_name": "Iм'я",
            "surname": "Прізвище",
            "birthday": "день народження | Формат: дд.мм.рррр",
            "email": "Адреса електронної пошти",
            "email_confirmation": "Повторіть електронну адресу",
            "email_optional": "Додаткова електронна адреса",
            "email_confirmation_optional": "Повторіть додаткову електронну адресу",
            "summary": "Резюме:",
            "phone": "додатковий номер телефону",
            "tel_information": " ",
            "appointment_for": " ",
            "appointment_around": " o",
            "appointment_for_only": "для ",
            "on": "на",
            "clock": "година",
            "message": "Додаткове повідомлення про зустріч.",
            "book_appointment": "Забронюйте зустріч зараз",
            "book_appointment_not_valid": "Будь ласка, заповніть усі позначені обов'язкові поля",
            "book_appointment_started": "Триває бронювання, зачекайте...",
            "book_appointment_failed": "Не вдалося забронювати зустріч, натисніть ще раз.",
            "terms_and_conditions": "Політика конфіденційності",
            "terms_and_conditions_text_for_confirm": "Використовуючи онлайн-запис на прийом, ви погоджуєтеся на обробку наданих вами персональних даних. Усі персональні дані зберігаються лише з метою обробки записів.",
            "terms_and_conditions_link": "Посилання на політику конфіденційності",
            "email_info": "Щоб підтвердження запису та будь-які інші необхідні документи могли бути надіслані вам електронною поштою,потрібна ваша адреса електронної пошти. Якщо ви цього не надасте, записатися онлайн на прийом, на жаль, буде неможливо.",
            "mobile_phone_number": "Номер мобільного телефону",
            "sms_notifications_label": "SMS-повідомлення",
            "sms_notifications_checkbox_text": "Я хотів би отримувати інформацію про свій запис за допомогою SMS-повідомлень.",
            "sms_notifications_text": "Ми не дзвонитимемо вам, а також не використовуватимемо номер вашого мобільного телефону для інших цілей.",
            "back": "Повернутися",
            "cancel": "Перервати",
            "close_privacy_policy": "Закрити політику конфіденційності",
            "confirm_policy": "Будь ласка, підтвердіть політику конфіденційності",
            "policy": "Я ознайомився з інформацією про захист даних і даю згоду на обробку моїх даних.",
            "show_policy": "Показати політику конфіденційності",
            "optional": "(необов'язковий)"
        },
        "dataforms_waiting_list_entry": {
            "header": "Запис в лист очікування",
            "waiting_list_entry_not_valid": "Будь ласка, заповніть усі позначені обов'язкові поля",
            "waiting_list_entry": "Зареєструйтесь зараз без зобов'язань",
            "waiting_list_entry_started": "Реєстрація списків очікування триває, будь ласка, зачекайте...",
            "go_to_waiting_list_entry": "Перейти до реєстрації в листі очікування",
            "successfull_registered": "Ми отримали вашу реєстрацію. Щоб це набуло чинності, натисніть посилання в електронному листі, який ми вам надіслали.",
            "register_waiting_list_entry": "запит на реєстрацію"
        },
        "forms": {
            "bday": {
                "day": "День",
                "month": "Місяць",
                "year": "Рік"
            },
            "email_confirm": "повторитиv",
            "honorific_prefix": {
                "mister": "Чоловік",
                "miss": "Жінка",
                "other": "Інше"
            },
            "validations": {
                "default": {
                    "required": "Ця інформація обов'язкова.",
                    "min": "Введіть принаймні два символи.",
                    "max": "Забагато символів.",
                    "letter_only": "У цьому полі дозволені лише літери.",
                    "numbers_only": "У цьому полі дозволені лише цифри."
                },
                "number": {
                    "min": "Будь ласка, введіть число більше 0.",
                    "max": "Будь ласка, введіть число менше 1000000."
                },
                "email": {
                    "required": "Будь ласка, введіть адресу електронної пошти.",
                    "not_matching": "Адреси електронної пошти не співпадають.",
                    "not_a_valid_email": "Будь ласка, введіть дійсну адресу електронної пошти."
                },
                "options": {
                    "required": "Будь ласка, виберіть варіант."
                },
                "name": {
                    "required": "Будь ласка, введіть своє ім'я."
                },
                "given_name": {
                    "required": "Будь ласка, введіть своє ім'я."
                },
                "family_name": {
                    "required": "Будь ласка, введіть своє прізвище."
                },
                "honorific_prefix": {
                    "required": "Будь ласка, виберіть звертання."
                },
                "street_address": {
                    "required": "Будь ласка, введіть вулицю, де ви живете. "
                },
                "postal_code": {
                    "required": "Будь ласка, введіть свій поштовий індекс."
                },
                "city": {
                    "required": "Будь ласка, вкажіть своє Місто проживання."
                },
                "country_name": {
                    "required": "Будь ласка, введіть свою країну походження."
                },
                "bday": {
                    "day": {
                        "required": "Будь ласка, введіть календарний день свого народження.",
                        "type_error": "Введіть значення від 1 до 31.",
                        "min": "Це недійсний календарний день.",
                        "max": "Це недійсний календарний день."
                    },
                    "month": {
                        "required": "Будь ласка, введіть календарний місяць вашого дня народження.",
                        "type_error": "Введіть значення від 1 до 12.",
                        "min": "Це недійсний календарний місяць.",
                        "max": "Це недійсний календарний місяць."
                    },
                    "year": {
                        "required": "Будь ласка, введіть календарний рік вашого дня народження.",
                        "type_error": "Введіть значення від 1880 до ${year}.",
                        "min": "Введіть значення від 1880 до 2020.",
                        "max": "Введіть значення від 1880 до 2020."
                    }
                },"card": {
                    "bg": {
                        "required": "Будь ласка, введіть ліву частину вашого номера BG.",
                        "type_error": "Будь ласка, введіть значення з 5 цифр.",
                        "min": "Це невірне значення для лівої частини вашого номера BG.",
                        "max": "Це невірне значення для лівої частини вашого номера BG."
                    },
                    "bg_number": {
                        "required": "Будь ласка, введіть праву частину вашого номера BG.",
                        "type_error": "Будь ласка, введіть значення з 7 цифр для правої частини вашого номера BG.",
                        "min": "Це невірне значення для правої частини вашого номера BG.",
                        "max": "Це невірне значення для правої частини вашого номера BG."
                    }
                },
                "sex": {
                    "required": "Будь ласка, введіть свою стать."
                },
                "tel": {
                    "required": "Будь ласка, введіть свій номер телефону.",
                    "not_valid": "Введіть дійсний номер телефону."
                },
                "terms_and_conditions": "Підтвердьте нашу політику конфіденційності.",
                "ident_number": "Ident number"
            }
        },
        "appointment_info": {
            "at": "в",
            "location": "Розташування",
            "header": "Резюме вашого запису",
            "title": "Вашу зустріч заброньовано.",
            "title_reserved": "Ваша зустріч ще не підтверджена.",
            "title_on_site_reserved": "Ваша зустріч зарезервована. Потрібне підтвердження на місці.",
            "title_desk_reserved": "Ваша зустріч зарезервована. Потрібне підтвердження працівника.",
            "infotext": "<h4>Push-повідомлення на вашому смартфоні.</h4> <p> Відскануйте QR-код за допомогою нашого додатка cleverQ на своєму смартфоні та отримуйте зручні push-повідомлення, щоб записатися на прийом. </p>",
            "confirmtext": "<h4>Примітки щодо підтвердження запису</h4><p> Ви забронювали зустріч у нас. Щоб бронювати це обов’язкове замовлення, ми додаємо підтвердження до електронного листа, надісланого вам. Натисніть посилання в цьому електронному листі. Цим ВИ записуєтеся на зустріч із нами.</p>",
            "on_site_confirmtext": "<h4>Підтвердження на місці</h4> <p> Будь ласка, не забудьте взяти цей документ із собою на прийом у цифровому вигляді на вашому смартфоні або в друкованому паперовому вигляді. Вам потрібен або код бронювання, або QR-код, щоб підтвердити вашу зустріч на сайті та зберегти її. </p>",
            "desk_confirmtext": "<h4>Підтвердження запису працівником</h4> <p>Ми переслали ваш запит на зустріч співробітнику. Щойно зустріч буде підтверджено працівником, ви отримаєте електронний лист із підтвердженням",
            "reservation_code": "Код бронювання",
            "summary": "Резюме",
            "issue": "Запит",
            "appointment": "Зустріч",
            "persons": "Особа",
            "person": "одна особа",
            "name": "Призвіще",
            "birthday": "День народження",
            "email": "Aдреса електронної пошти",
            "phone": "Hомер телефону",
            "message": "Повідомлення",
            "back_button": "Hазад до постачальника",
            "print_button": "Роздрукувати",
            "advertising": "<h4>cleverQ – програма для смартфонів</h4> <p>Завантажте нашу безкоштовну програму cleverQ і користуйтеся перевагами свого смартфона. <br/> Замовляйте зустрічі, купуйте квитки, push-сповіщення, усе на ходу.<br/> Тож ви завжди в курсі подій.",
            "advertising_image": "/images/smartphone_english.png",
            "duplicate_info_title": "Дублікат запису на прийом",
            "duplicate_info_title_remove": "Будь ласка, скасуйте свою стару зустріч",
            "duplicate_info_text": "Ви вже забронювали зустріч. Будь ласка, скасуйте свою стару зустріч, перш ніж забронювати нову. Ви можете скасувати стару зустріч, перевіривши електронний лист, надісланий вам зі старою зустріччю.",
            "slot_already_booked_title": "Запис на прийом неможливий",
            "slot_already_booked_text": "На жаль, дату, яку ви запитували, тим часом забронював хтось інший. Просимо вибачення за незручності. Будь ласка, виберіть іншу дату."
        },
        "navigation": {
            "cleverq": "Бо хто любить чекати?",
            "legal_notice": "Bихідні дані",
            "policy": "Kонфіденційність"
        },
        "greetings": {
            "confirm_greetings": "Перейдіть до запису на зустріч"
        },
        "checkout_title": {
            "your": "Ваш",
            "choice": "Bибір"
        },
        "enter_invite_code": {
            "verify": "Перевірити",
            "invite_code":"Kод запрошення"
        },
        "appointment_times": {
            "at": "на ",
            "on": "o ",
            "o_clock": "годині",
            "header": "Ваш зарезервований час зустрічі.",
            "remove": "Виберіть новий час зустрічі",
            "session": {
                "text": "Ваш сеанс закінчується через: ",
                "close_error_modal": "Закрити",
                "reservation_expired": "Термін бронювання закінчився, будь ласка, виберіть новий час зустрічі.",
                "expired": "Ваш сеанс закінчився, оскільки ви були неактивні занадто довго."
            }
        }
    }
}