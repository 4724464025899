import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { injectIntl } from "react-intl";

class Greetings extends Component {
  constructor() {
    super();
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  handleClick() {
    this.props.confirmGreetings()
  }
  render() {
    const {intl} = this.props
    const Button = styled.button`
      background: ${this.props.site_info.confirm_button_background};
      color: ${this.props.site_info.confirm_button_text};
      border: ${this.props.site_info.border_on_items ? '1px solid black' : 'none'};
      text-align: left;
      font-size: 1.2em;
      padding: 15px;
      font-weight: 600;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    return (
      <Fragment>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        <div
          className={`container animated fadeIn ${
            this.props.site_info.drop_shadows ? 'drop-shadow' : null
          }`}
          style={{paddingTop: "10px"}}
        >
          <div className="row">
            <div className="col-md-12 issues_header_left">
              <span className="no_tab">{this.props.site_info.greetings_header}</span>
            </div>
          </div>
          <div
            className="row inline-shadow"
            style={{
              backgroundColor: this.props.site_info.content_background,
              color: this.props.site_info.content_text,
              fontSize: '1.6rem'
            }}>
            <div style={{ marginTop: '10px' }} />
            <div className="col-md-12">
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.greetings_content)} />
            </div>
          </div>
        </div>
        <div onClick={this.handleClick.bind(this)} style={{marginTop: '10px'}}>
          <Button
            type="submit"
            style={{borderRadius: this.props.site_info.round_buttons ? `${this.props.site_info.round_buttons_value}px` : '0'}}
            className={`${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}>
            {this.props.site_info.use_icons ? <span dangerouslySetInnerHTML={this.createMarkup(
              this.props.site_info.icon_continue
            )} style={{paddingRight: '10px'}}/> : null}{intl.formatMessage({id: 'greetings.confirm_greetings'})}
          </Button>
        </div>
      </Fragment>
    );
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    session_info: state.session_info
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Greetings)
);
