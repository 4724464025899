import React, { Component, Fragment } from 'react'
import { useFormikContext, Formik, Form, Field } from 'formik'
import { connect } from 'react-redux';
import * as actions from '../../actions';
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { CustomField } from './elements/FormTypes'
import {
  validateText,
  validateNumber,
  validateTextArea,
  validateOptions,
  validateCheckBox,
  validateHonorificPrefix,
  validateHonorificPrefixOther,
  validateName,
  validateGivenName,
  validateFamilyName,
  validateEmailRequired,
  validateEmail,
  validateTel,
  validateBirthday,
  validateStreetAddress,
  validatePostalCode,
  validateCity,
  validateCountryName,
  validateTermsAndConditions,
  validateCustomerCardNumber
} from './elements/Validations'
import styles from '../../styles/dataforms.module.css'
import styled from 'styled-components'
import moment from 'moment'
import ReCAPTCHA from "react-google-recaptcha";
import ReactCountdownClock from 'react-countdown-clock'
import Modal from "react-modal";
import Helmet from "react-helmet";
import Keyboard from './elements/Keyboard'
import {setTouchscreenMode} from "../../actions";
import PrivacyPolicy from "./PrivacyPolicyTouch";
import {FormattedMessage, injectIntl} from "react-intl";

let schema_array = []
let initialValues = {}
const query = new URLSearchParams(window.location.search);
const invite_url_token = query.get('url_token') ? query.get('url_token') : '';
const not_publicy_listed_token = query.get('hash') ? query.get('hash') : '';
const samsung_keyboard = query.get('samsung_keyboard') === "true"
const touchscreen_mode = query.get('touchscreen_mode') === 'true'
let language;

let appointment_time_countdown

let dataFieldKeysAndValuesFromParamsArray = [];
const paramsFromUrl = new URLSearchParams(window.location.search);
paramsFromUrl.forEach((value, key) => {
  dataFieldKeysAndValuesFromParamsArray.push({key: key, value: value});
})

class DataFormsNew extends Component {
  constructor() {
    super();

    this.formikRef = React.createRef();

    this.state = {
      submitted: false,
      recaptcha_token: null,
      booking_started: false,
      show_retry_button: false,
      counter: null,
      show_error_modal: false,
      modal_error_message: '',
      slot_reservation_time: null,
      show_mobile_phone_field: false,
      mobile_phone_number: null,
      active_input_name: null,
      active_input_type: null
    }
  }
  closeErrorModal = () => {
    window.location.reload()
  }
  componentDidMount() {
    if(query.get('touchscreen_mode') === 'true') {
      this.props.setTouchscreenMode(true)
    }
  }

  componentWillUnmount() {
    clearInterval(appointment_time_countdown)
  }
  onChangeRecaptcha = (value) => {
    this.setState({ recaptcha_token: value })
  }
  loginToServiceAccount = (e) => {
    e.preventDefault();
    let subtask_items = [];
    this.props.user_person_count.forEach(item => {
      subtask_items.push(item)
    })
    let URI;
    language = query.get('lang') && query.get('lang').length > 1  ? query.get('lang') : this.props.default_locale
    if (subtask_items.length) {
      URI = `${window.location.origin}/users/auth/paderborn?site_id=${this.props.site_info.id}&lang=${language}&service_id=${this.props.selected_service}&subtask_id=${this.props.selected_subtask[0].id}&day=${this.props.selected_day}&slot_id=${this.props.selected_slot}&time_of_slot=${this.props.selected_slot_time_of_slot}&subtask_items[]=${JSON.stringify(subtask_items)}`
    } else {
      URI = `${window.location.origin}/users/auth/paderborn?site_id=${this.props.site_info.id}&lang=${language}&service_id=${this.props.selected_service}&day=${this.props.selected_day}&slot_id=${this.props.selected_slot}&time_of_slot=${this.props.selected_slot_time_of_slot}`
    }
    window.location.replace(encodeURI(URI))
  }
  showRetryButton = () => {
    this.setState({ show_retry_button: true })
  }
  showPolicy = (e) => {
    e.preventDefault()
    this.props.setShowPolicy();
  };
  setShowMobilePhoneField = () => {
    this.setState({ show_mobile_phone_field: !this.state.show_mobile_phone_field })
  }
  renderTimer = (expiration) => {
    let now = moment()
    let end = moment(expiration)
    let duration = moment.duration(end.diff(now));
    let time = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')

    return <span style={duration.as('seconds') < 180 ? {color: 'red'} : {color: 'green'}}>{time}</span>
  }
  handleActiveInputName = (input_name, input_type, input_label) => {
    if(touchscreen_mode && !samsung_keyboard) {
      this.setState({ active_input_name: input_name, active_input_type: input_type, active_input_label: input_label })
    }
  }
  resetActiveInput = () => {
    this.setState({ active_input_name: null, active_input_type: null })
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    language = query.get('lang') && query.get('lang').length > 1  ? query.get('lang') : this.props.default_locale
    const server = window.location.hostname
    const {
      site_info,
      selected_service_data,
      session_info,
      selected_service,
      selected_service_name,
      selected_day,
      selected_slot,
      selected_slot_time_of_slot,
      selected_subtask,
      user_person_count,
      selected_language,
      intl} = this.props
    const Button = styled.button`
      background: ${this.props.site_info.confirm_button_background};
      color: ${this.props.site_info.confirm_button_text};
      border: ${this.props.site_info.border_on_items ? '1px solid black !important' : 'none'};
      text-align: left;
      font-size: 1.1em;
      padding: 13px;
      font-weight: 600;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    let rows = []
    let fields = []
    let follow_up_invite = false
    let touchscreen_info_fields = []
    let convert_group_by_row = null
    let group_by_row = null

    if (this.props.ui.validated && this.props.ui.validated.user_infos && this.props.ui.validated.user_infos.length > 0) {
      follow_up_invite = true
    }

    const filteredInfoFields = selected_service_data.info_fields.filter(infoField => infoField.subtasks_ids.length === 0 ||
      _.some(this.props.selected_subtask, selectedSubtask => infoField.subtasks_ids.includes(selectedSubtask.id)));
    if(touchscreen_mode) {
      filteredInfoFields.map(field => touchscreen_mode &&  field.show_on_touchscreen === false ? field.mandatory = false : field.mandatory)
      touchscreen_info_fields = _.groupBy(filteredInfoFields.filter(field => field.show_on_touchscreen === true), 'row')
      convert_group_by_row = Object.keys(touchscreen_info_fields).map((key) => {
        return touchscreen_info_fields[key]
      })
    } else {
      group_by_row = _.groupBy(filteredInfoFields, 'row')
      convert_group_by_row = Object.keys(group_by_row).map((key) => {
        return group_by_row[key]
      })
    }

    let root = document.documentElement
    root.style.setProperty('--selected-color', site_info.button_active)
    root.style.setProperty('--checkbox-arrow', site_info.button_text_active)
    root.style.setProperty('--selected-border-color', site_info.button_active)
    if (touchscreen_mode) {
      root.style.setProperty('--border-color', '#c9c9c9')
    }
    convert_group_by_row.forEach((row, i) => {
      row.map((field) => {
        const mandatory = touchscreen_mode && field.show_on_touchscreen === false ? field.mandatory = false : field.mandatory
        if (!field.desk_only) {
          validationMapper(
            field.id,
            field.type,
            mandatory,
            field.mandatory_for_users,
            session_info.isUser,
            this.props.user_infos ? JSON.parse(this.props.user_infos) : null,
            this.props.ui.validated.user_infos,
            findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name))
          if (row.length < 2) {
            fields.push(
              field.type === 'email' ? (
                email_helper(field, site_info.drop_shadows, session_info.isUser, follow_up_invite, this.props.ui.validated.user_infos, site_info.send_confirm_request_booked_as_user, site_info.custom_email_required_text, this.handleActiveInputName, intl)
              ) : (
                <Fragment key={uuidv4()}>
                  <div className={styles['col-half']}>
                    {field.column === 1 ? (
                      <Field
                        name={field.id}
                        as={CustomField}
                        type={field.type}
                        label={field.label}
                        required={session_info.isUser ? field.mandatory_for_users : field.mandatory}
                        shadow={site_info.drop_shadows}
                        options={field.options}
                        readOnly={findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name) || follow_up_invite}
                        activeInputName={(input_name) => this.handleActiveInputName(input_name, field.type, field.label)}
                      />
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                  <div className={styles['col-half']}>
                    {field.column === 2 ? (
                      <Field
                        name={field.id}
                        as={CustomField}
                        type={field.type}
                        label={field.label}
                        required={session_info.isUser ? field.mandatory_for_users : field.mandatory}
                        shadow={site_info.drop_shadows}
                        options={field.options}
                        readOnly={findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name) || follow_up_invite}
                        activeInputName={(input_name) => this.handleActiveInputName(input_name, field.type, field.label)}
                      />
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                </Fragment>
              )
            )
          } else if (row.length > 2) {
            fields.push(
              field.type === 'email' ? (
                <div key={uuidv4()} className={styles['col-third']}>
                  {email_helper(field, site_info.drop_shadows, session_info.isUser, follow_up_invite, this.props.ui.validated.user_infos, site_info.send_confirm_request_booked_as_user, site_info.custom_email_required_text, this.handleActiveInputName, intl)}
                </div>
              ) : (
                <div key={uuidv4()} className={styles['col-third']}>
                  <Field
                    name={field.id}
                    as={CustomField}
                    type={field.type}
                    label={field.label}
                    required={session_info.isUser ? field.mandatory_for_users : field.mandatory}
                    shadow={site_info.drop_shadows}
                    options={field.options}
                    readOnly={findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name) || follow_up_invite}
                    activeInputName={(input_name) => this.handleActiveInputName(input_name, field.type, field.label)}
                  />
                </div>
              )
            )
          } else {
            fields.push(
              field.type === 'email' ? (
                <div key={uuidv4()}>{email_helper(field, site_info.drop_shadows, session_info.isUser, follow_up_invite, this.props.ui.validated.user_infos, site_info.send_confirm_request_booked_as_user, site_info.custom_email_required_text, this.handleActiveInputName, intl)}</div>
              ) : (
                <div key={uuidv4()} className={styles['col-half']}>
                  <Field
                    name={field.id}
                    as={CustomField}
                    type={field.type}
                    label={field.label}
                    required={session_info.isUser ? field.mandatory_for_users : field.mandatory}
                    shadow={site_info.drop_shadows}
                    options={field.options}
                    readOnly={findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name) || follow_up_invite}
                    activeInputName={(input_name) => this.handleActiveInputName(input_name, field.type, field.label)}
                  />
                </div>
              )
            )
          }
        }
        return null
      })
      rows.push(
        <div key={uuidv4()} className={styles['row-forms']}>
          {fields}
        </div>
      )
      fields = []
    })

    let isSubmitLocked = false;

    const modalCustomStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        width                 : '50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 110
      },
    };
    return (
      <Fragment>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        <Formik
          innerRef={this.formikRef}
          initialValues={Object.assign(initialValues, { TermsAndConditions: site_info.show_policy_checkbox ? false : true })}
          validationSchema={mergeSchemas(schema_array)}
          onSubmit={(values, { setSubmitting, resetForm}) => {
            this.setState({ booking_started: true })
            // Convert fields if neccacary
            let user_infos = []
            let email
            let mobile_phone_number_field = document.getElementById("mobile_phone_number")
            let mobile_phone_number

            if (mobile_phone_number_field) {
              mobile_phone_number = mobile_phone_number_field.value
            }

            for (let [key, value] of Object.entries(values)) {
              let check = key.substring(0, 4) === 'bday' ? 'bday' : null
              let check_bg = key.substring(0, 20) === 'customer_card_number' ? 'customer_card_number' : null
              switch (key) {
                case 'email':
                  email = value
                  user_infos.push({id: selected_service_data.info_fields.find(item => item.type === 'email').id, value: value})
                  break;
                default:
                  if (key !== 'TermsAndConditions' && key !== 'email_confirm' && key !== 'MobilePhoneNumber' && key && !check && !check_bg) {
                    user_infos.push({id: parseInt(key), value: value})
                  }
                  if (check) {
                    let day = value.day.length < 2 ? '0' + value.day : value.day
                    let month = value.month.length < 2 ? '0' + value.month : value.month
                    let year = value.year
                    let item_id = value.item_id

                    if (day === '0' || month === '0') {
                      /* When one of these are === '0' this code prevents it to be pushed to user_infos array with wrong values */
                      user_infos.push({id: item_id, value: ''})
                    } else {
                      user_infos.push({id: item_id, value: [day, month, year].join('.')})
                    }
                  }
                  if (check_bg) {
                    user_infos.push({id: value.item_id, value: [value.bg, value.bg_number].join('//')})
                  }
              }
            }
            // NRW Portalkonto
            const nrw_portal_konto_id = JSON.parse(this.props.user_infos) ? JSON.parse(this.props.user_infos).sub : null
            // create appointment
            if (!isSubmitLocked) {
              isSubmitLocked = true;
              if ( typeof email === 'undefined') {
                const d1 = new Date();
                const result = d1.getTime();
                //email = result.toString() + '@noemail.de'
                email = ''
              }
              let response = this.props.bookReservations(
                this.props.site_info.id,
                this.props.appointment_times.map(time => ( { key: time.reservation_key }) ),
                _.sortBy(user_infos, item => item.id),
                email,
                !email.length,
                language,
                invite_url_token,
                nrw_portal_konto_id,
                this.state.recaptcha_token,
                not_publicy_listed_token,
                this.props.selected_service,
                mobile_phone_number
              ).then(res => {
                //this.setState({ booking_started: false })
                isSubmitLocked = false
                if ([1, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12, 13, 14, 15, 16].includes(res.payload.code)) {
                  this.setState({ show_retry_button: false, show_error_modal: true, modal_error_message: res.payload.message })
                }
                if (res.payload.code === 2) {
                  setTimeout(() => {
                    let response = this.props.bookReservations(
                      this.props.site_info.id,
                      this.props.appointment_times.map(time => ( { key: time.reservation_key }) ),
                      _.sortBy(user_infos, item => item.id),
                      email,
                      !email.length,
                      language,
                      invite_url_token,
                      nrw_portal_konto_id,
                      this.state.recaptcha_token,
                      not_publicy_listed_token,
                      this.props.selected_service,
                      mobile_phone_number
                    ).then(res => {
                      isSubmitLocked = false
                    }).catch(err => {
                      isSubmitLocked = false
                    })
                  }, 3000)
                }
              }).catch(err => {
                isSubmitLocked = false
              })}

            this.setState({ submitted: true })
            if (this.props.booking.id) {
              isSubmitLocked = true
            }
            //resetForm();
          }}
        >
          {({ isValid, dirty }) => (
            <div
              className={`container animated fadeIn ${
                this.props.site_info.drop_shadows ? 'drop-shadow' : null
              }`}>

              <div className="row">
                <div className="col-md-12 issues_header_left" id="scroll_target_data_forms">
                  <span>{intl.formatMessage({id: "dataforms.header"})}</span>
                </div>
              </div>
              <Form className="form-group" name="appointment_form">
                <div
                  className="row inline-shadow data-forms-padding"
                  style={{
                    backgroundColor: site_info.content_background,
                    color: site_info.content_text
                  }}>
                  {site_info.show_data_delete ?
                    <div className="row">
                      <p style={{ marginLeft: '15px' }}>
                        {intl.formatMessage({id: "dataforms.data_delete"})}
                        <span style={{float: 'right', marginRight: '15px'}}><span style={{color: 'red'}}>*</span> {intl.formatMessage({id: 'dataforms.mandatory_fields'})}</span>
                      </p>
                    </div> :
                    <div className="row">
                      <p style={{ marginLeft: '15px' }}>
                        {site_info.custom_data_security_notice? site_info.custom_data_security_notice : null}
                        <span style={{float: 'right', marginRight: '15px'}}><span style={{color: 'red'}}>*</span> {intl.formatMessage({id: 'dataforms.mandatory_fields'})}</span>
                      </p>
                    </div>
                  }
                  {this.props.site_info.show_nrw_service_portal_button_temp ? (server === 'cqm.cleverq.de' && site_info.customer_id === 131 && site_info.id === 184) || (server === 'cqm.cleverq.de' && site_info.customer_id === 131 && site_info.id === 224) || (server === 'cqm.cleverq.de' && site_info.customer_id === 131 && site_info.id === 238) || (server === 'cqm.cleverq.de' && site_info.customer_id === 131 && site_info.id === 246)?
                    <img style={{ cursor: 'pointer'}} src="/images/nrw_service_konto_logo.png" alt="login mit service konto nrw" onClick={(e) => this.loginToServiceAccount(e)} />
                    : null : null
                  }
                  {rows}

                  <hr />
                  <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-md-6">
                      <p
                        style={{
                          fontSize: '1.1em',
                          marginTop: '15px',
                          color: site_info.summary_text_color
                        }}>
                        {intl.formatMessage({id: 'dataforms.summary'})}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p style={{fontSize: '1.1em'}}>{intl.formatMessage({id: 'dataforms.tel_information'})}</p>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: '0px' }}>
                    <div className="col-md-6">
                      <span
                        style={{ color: site_info.summary_text_color }}>
                        {intl.formatMessage({id: 'dataforms.appointment_for'})}{' '}
                        <ul className="list-group">
                          {user_person_count.length ? (
                            user_person_count.map(item => {
                              if (item.number > 0) {
                                return (
                                    <li
                                        key={item.subtask_id}
                                        style={{fontSize: '1.1em'}}
                                        className={`list-group-item d-flex justify-content-between align-items-center ${
                                            site_info.drop_shadows
                                                ? 'drop-shadow'
                                                : null
                                        }`}>
                                      <span dangerouslySetInnerHTML={this.createMarkup(item.name)}/>
                                      <span className="badge badge-primary badge-pill">
                                      {item.number}
                                    </span>
                                    </li>
                                );
                              } else {
                                return null;
                              }
                            })
                          ) : (
                            <li
                              style={{fontSize: '1.1em'}}
                              className={`list-group-item d-flex justify-content-between align-items-center ${
                                site_info.drop_shadows
                                  ? 'drop-shadow'
                                  : null
                              }`}>
                              {<div dangerouslySetInnerHTML={this.createMarkup(selected_service_name)}/>}
                            </li>
                          )}
                        </ul>
                        <div style={{fontSize: '1.1em'}}>
                          {site_info.location_text_in_summary}{' '}{intl.formatMessage({id: 'dataforms.on'})}{' '}
                          {moment(selected_day).format('DD.MM.YYYY')}
                          {intl.formatMessage({id: 'dataforms.appointment_around'})}{' '}
                          {selected_slot_time_of_slot}{' '}
                          {intl.formatMessage({id: 'dataforms.clock'})}
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className={styles['row-forms']}>
                    <hr />
                    {site_info.allow_sms_appointments ?
                      <div className={styles['row-forms']}>
                        <label className={styles['label-margin']}>
                          {intl.formatMessage({id: 'dataforms.sms_notifications_label'})} <span className={styles.optional}>&nbsp; &nbsp;<FormattedMessage id={"dataforms.optional"} /></span>
                        </label>
                        <div className={styles['input-group']}>
                          <input
                            type="checkbox"
                            name={'mobile_phone_checkbox'}
                            onChange={this.setShowMobilePhoneField.bind(this)}
                            id="mobile_checkbox"
                            checked={this.state.show_mobile_phone_field}
                          />
                          <label className={styles['terms-text']} htmlFor="mobile_checkbox">{site_info.custom_sms_option_text && site_info.custom_sms_option_text.length > 0 ? site_info.custom_sms_option_text : intl.formatMessage({id: 'dataforms.sms_notifications_checkbox_text'})}</label>
                        </div>
                        {this.state.show_mobile_phone_field ?
                          <div className={styles['col-half']}>
                            <p>{intl.formatMessage({id: 'dataforms.sms_notifications_text'})}</p>
                            <Field
                              name={'MobilePhoneNumber'}
                              as={CustomField}
                              type={"MobilePhoneNumber"}
                              label={intl.formatMessage({id: 'dataforms.mobile_phone_number'})}
                              shadow={site_info.drop_shadows}
                              possible_phone_country_codes={this.props.site_info.possible_phone_country_codes}
                              default_phone_country_code={this.props.site_info.default_phone_country_code}
                            />
                          </div> : null
                        }
                      </div> : null}
                    { site_info.show_policy_checkbox ?
                      <div>
                        <Field
                          name={'TermsAndConditions'}
                          as={CustomField}
                          type={"TermsAndConditions"}
                          label={intl.formatMessage({id: 'dataforms.terms_and_conditions'})}
                          policy_text={createMarkup(
                            site_info.policy_text
                          )}
                          touchscreen_mode={touchscreen_mode}
                          shadow={site_info.drop_shadows}
                        />
                        {touchscreen_mode && !(isValid && dirty)? (
                          <button
                            onClick={this.showPolicy.bind(this)}
                            className={`btn col-md-6 ${
                              this.props.site_info.drop_shadows ? 'drop-shadow' : null
                            }`}
                            style={{
                              marginTop: '10px',
                              fontSize: '1.1em',
                              padding: '12px',
                              border: '1px solid #FFCE00',
                              fontWeight: '600',
                              backgroundColor: '#FFCE00',
                              color: 'black'
                            }}>
                            {intl.formatMessage({id: "dataforms.show_policy"})}
                          </button>
                        ) : null}
                      </div> :
                      <div>
                        <p style={{fontSize: '1.1.em'}}>
                          {<span dangerouslySetInnerHTML={createMarkup(intl.formatMessage({id: 'dataforms.terms_and_conditions_text_for_confirm'}))} />}
                        </p>
                        <p><a href={site_info.customer_policy_url} target="_blank"
                              rel="noopener noreferrer">{intl.formatMessage({id: 'dataforms.terms_and_conditions_link'})}</a>
                        </p>
                      </div>
                    }
                  </div>

                  <div className="row">
                    <div className="col-md-12" style={{
                      marginTop: '5px',
                      marginLeft: site_info.recaptcha && !session_info.isUser ? '-15px' : 0
                    }}>
                      {(isValid && dirty || this.state.recaptcha_token) ? site_info.recaptcha && !session_info.isUser ?
                        <div style={{marginBottom: '10px'}} className="col-md-6">
                          <ReCAPTCHA
                            sitekey={this.props.recaptcha_site_key}
                            onChange={this.onChangeRecaptcha}
                            size="normal"
                          />
                        </div> : null : null
                      }
                      {this.state.recaptcha_token || !site_info.recaptcha || session_info.isUser ? isValid && dirty ?
                        !this.props.booking.error ?
                          !touchscreen_mode ?
                            <Button
                              type="submit"
                              disabled={this.props.booking.id ? true : false}
                              style={{ borderRadius: site_info.round_buttons ? `${site_info.round_buttons_value}px` : '0' }}
                              className={`btn col-md-6 ${
                                site_info.drop_shadows ? 'drop-shadow' : null
                              }`}>
                              {site_info.use_icons && !this.state.booking_started ? <span dangerouslySetInnerHTML={createMarkup(
                                site_info.icon_confirm_button
                              )} style={{paddingRight: '10px', textAlign: this.state.booking_started ? 'center' : 'left'}}/> : null}
                              {this.state.booking_started ? <div><i className='fa fa-sync-alt fa-spin' style={{marginRight: '15px'}}></i>{intl.formatMessage({id: 'dataforms.book_appointment_started'})}</div> : intl.formatMessage({id: 'dataforms.book_appointment'})}
                            </Button>
                            :
                            <Button
                              type="submit"
                              disabled={this.props.booking.id ? true : false}
                              className={`btn col-md-6 ${
                                site_info.drop_shadows ? 'drop-shadow' : null
                              }`}
                              style={{textAlign: 'center'}}
                            >
                              {site_info.use_icons && !this.state.booking_started ?
                                <span dangerouslySetInnerHTML={createMarkup(
                                  site_info.icon_confirm_button
                                )} style={{
                                  paddingRight: '10px',
                                  textAlign: this.state.booking_started ? 'center' : 'left'
                                }}/> : null}
                              {this.state.booking_started ? <div><i className='fa fa-sync-alt fa-spin'
                                                                    style={{marginRight: '15px'}}></i>{intl.formatMessage({id: 'dataforms.book_appointment_started'})}
                              </div> : intl.formatMessage({id: 'dataforms.book_appointment'})}
                            </Button> :
                          this.state.show_retry_button ?
                            <Button
                              type="submit"
                              className={`col-md-6 ${
                                site_info.drop_shadows ? 'drop-shadow' : null
                              }`}>
                              {site_info.use_icons && !this.state.booking_started ? <span dangerouslySetInnerHTML={createMarkup(
                                site_info.icon_confirm_button
                              )} style={{paddingRight: '10px', textAlign: this.state.booking_started ? 'center' : 'left'}}/> : null}{intl.formatMessage({id: 'dataforms.book_appointment_failed'})}
                            </Button> :
                            <ReactCountdownClock seconds={6}
                                                 color="#000"
                                                 alpha={1}
                                                 size={60}
                                                 onComplete={this.showRetryButton} />
                        : null : null
                      }
                    </div>
                    {!this.state.submitted && !isValid ?
                      <div className="col-md-6" style={{ marginTop: '5px' }}>
                        <span style={{color: 'red', fontSize: '1.1em'}}>{intl.formatMessage({id: 'dataforms.book_appointment_not_valid'})}</span>
                      </div>
                      : null
                    }
                  </div>
                  {
                    touchscreen_mode && !samsung_keyboard ?
                      <div className="row" style={{minHeight: '460px'}}>
                      </div> : null
                  }
                </div>
                <PrivacyPolicy />
              </Form>
            </div>
          )}
        </Formik>
        {touchscreen_mode && !samsung_keyboard ?
          <Keyboard
            active_input_name={this.state.active_input_name}
            active_input_type={this.state.active_input_type}
            active_input_label={this.state.active_input_label}
            reset_active_input={this.resetActiveInput}
            formikRef={this.formikRef}
          />
          : null}
        <Modal
          isOpen={this.state.show_error_modal}
          onRequestClose={this.closeErrorModal.bind(this)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={modalCustomStyles}
        >
          <span style={{fontWeight: 'bold', color: 'red', fontSize: '1.6rem'}}>{this.state.modal_error_message}</span><br/>

          <button
            className={`btn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}
            onClick={this.closeErrorModal}
            style={{
              marginTop: '10px',
              background: this.props.site_info.confirm_button_background,
              color: this.props.site_info.confirm_button_text,
              border: '1px solid #CBCBCBFF'
            }}>
            {intl.formatMessage({id: 'appointment_times.session.close_error_modal'})}
          </button>
        </Modal>
      </Fragment>
    )
  }
}

const SaveDataformValues = (props) => {
  const { values } = useFormikContext()
  props.getDataFormValues(values)

  return null
}
const mergeSchemas = (array) => {
  if (array.length > 0) {
    const [first, ...rest] = array

    return rest
      .reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)
      .concat(validateTermsAndConditions)
  } else {
    return validateTermsAndConditions
  }
}

const validationMapper = (id, type, mandatory, mandatory_for_users, isUser, openid_user_infos, follow_up_user_infos, value) => {
  let schema
  if (!follow_up_user_infos) {
    switch (type) {
      case 'text':
        schema = isUser ? validateText(id, mandatory_for_users) :  validateText(id, mandatory)
        Object.assign(initialValues, { [id]: value ? value : '' })
        break
      case 'number':
        schema = isUser ? validateNumber(id, mandatory_for_users) :  validateNumber(id, mandatory)
        Object.assign(initialValues, { [id]: value ? value : '' })
        break
      case 'text_area':
        schema = isUser ? validateTextArea(id, mandatory_for_users) :  validateTextArea(id, mandatory)
        Object.assign(initialValues, { [id]: value ? value : '' })
        break
      case 'options':
        schema = isUser ? validateOptions(id, mandatory_for_users) :  validateOptions(id, mandatory)
        Object.assign(initialValues, { [id]: value ? value : '' })
        break;
      case 'check_box':
        schema = isUser ? validateCheckBox(id, mandatory_for_users) :  validateCheckBox(id, mandatory)
        Object.assign(initialValues, { [id]: false })
        break
      case 'honorific_prefix':
        let prefix = ''
        if (openid_user_infos && openid_user_infos.gender && openid_user_infos.gender.length) {
          if (openid_user_infos.gender === 'male') {
            prefix = 'Herr'
          } else {
            prefix = 'Frau'
          }
        }
        schema = isUser ? validateHonorificPrefix(id, mandatory_for_users) :  validateHonorificPrefix(id, mandatory)
        Object.assign(initialValues, { [id]: prefix })
        break
      case 'honorific_prefix_other':
        let prefix_other = ''
        if (openid_user_infos && openid_user_infos.gender && openid_user_infos.gender.length) {
          if (openid_user_infos.gender === 'male') {
            prefix_other = 'Herr'
          } else {
            prefix_other = 'Frau'
          }
        }
        schema = isUser ? validateHonorificPrefixOther(id, mandatory_for_users) :  validateHonorificPrefixOther(id, mandatory)
        Object.assign(initialValues, { [id]: prefix_other })
        break
      case 'name':
        schema = isUser ? validateName(id, mandatory_for_users) :  validateName(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.name : value ? value : ''  })
        break
      case 'given_name':
        schema = isUser ? validateGivenName(id, mandatory_for_users) :  validateGivenName(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.given_name : value ? value : '' })
        break
      case 'family_name':
        schema = isUser ? validateFamilyName(id, mandatory_for_users) :  validateFamilyName(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.family_name : value ? value : ''  })
        break
      case 'email':
        schema = isUser && mandatory_for_users ? validateEmailRequired : validateEmail
        Object.assign(initialValues, { email: openid_user_infos ? openid_user_infos.email : value ? value : ''  })
        Object.assign(initialValues, { email_confirm: openid_user_infos ? openid_user_infos.email : value ? value : ''  })
        break
      case 'tel':
        let phone_number = null
        if (openid_user_infos) {
          if (openid_user_infos.phone_number.length) {
            phone_number = openid_user_infos.phone_number
          } else {
            phone_number = openid_user_infos.private_mobile
          }
        }
        schema = isUser ? validateTel(id, mandatory_for_users) :  validateTel(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? phone_number && phone_number.length ? phone_number  : '' : value ? value : '' })
        break
      case 'bday':
        let birthdate = null
        schema = isUser ? validateBirthday(id, mandatory_for_users) :  validateBirthday(id, mandatory)
        if (value) {
          birthdate = value.split(';')
          Object.assign(initialValues, { ['bday_' + id]: birthdate ? { day: birthdate[2], month: birthdate[1], year: birthdate[0], item_id: id} : { day: '', month: '', year: '', item_id: id}})

        } else {
          if (openid_user_infos && openid_user_infos.birthdate && openid_user_infos.birthdate.length) {
            birthdate = openid_user_infos.birthdate.split('-')
          }
          Object.assign(initialValues, { ['bday_' + id]: birthdate ? { day: birthdate[2], month: birthdate[1], year: birthdate[0], item_id: id} : { day: '', month: '', year: '', item_id: id}})
        }
        break
      case 'street_address':
        schema = isUser ? validateStreetAddress(id, mandatory_for_users) :  validateStreetAddress(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.street_address : '' })
        break
      case 'postal_code':
        schema = isUser ? validatePostalCode(id, mandatory_for_users) :  validatePostalCode(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.postal_code : '' })
        break
      case 'city':
        schema = isUser ? validateCity(id, mandatory_for_users) :  validateCity(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.locality : value ? value : '' })
        break
      case 'country_name':
        schema = isUser ? validateCountryName(id, mandatory_for_users) :  validateCountryName(id, mandatory)
        Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.country : value ? value : '' })
        break
      case 'customer_card_number':
        let bgnumber = null
        if (openid_user_infos && openid_user_infos.customer_card_number && openid_user_infos.customer_card_number.length) {
          bgnumber = openid_user_infos.customer_card_number.split('//')
        }
        schema = isUser ? validateCustomerCardNumber(id, mandatory_for_users) :  validateCustomerCardNumber(id, mandatory)
        Object.assign(initialValues, { ['customer_card_number_' + id]: bgnumber ? { bg: bgnumber[0], bg_number: bgnumber[1], item_id: id} : { bg: '', bg_number: '', item_id: id}})
        break
      default:
        console.log(type)
    }
  } else {
    switch (type) {
      case 'text':
        let text = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'text'; })]
        schema = validateText(id, mandatory)
        if (text) {
          Object.assign(initialValues, { [id]: text.value  })
        } else {
          Object.assign(initialValues, { [id]: ''  })
        }
        break
      case 'number':
        let number = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'number'; })]
        schema = validateNumber(id, mandatory)
        if (number) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'number'; })].value  })
        } else {
          Object.assign(initialValues, { [id]: ''  })
        }
        break
      case 'text_area':
        schema = validateTextArea(id, mandatory)
        Object.assign(initialValues, { [id]: '' })
        break
      case 'options':
        let options = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'options'; })]
        schema = validateOptions(id, mandatory)
        if (options) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'options'; })].value  })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break;
      case 'check_box':
        let check_box = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'check_box'; })]
        schema = validateCheckBox(id, mandatory)
        if (check_box) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'check_box'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'honorific_prefix':
        let honorific_prefix = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'honorific_prefix' || o.type == 'honorific_prefix_other'; })]
        schema = validateHonorificPrefix(id, mandatory)
        if (honorific_prefix) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'honorific_prefix' || o.type == 'honorific_prefix_other'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'honorific_prefix_other':
        let honorific_prefix_other = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'honorific_prefix' || o.type == 'honorific_prefix_other'; })]
        schema = validateHonorificPrefix(id, mandatory)
        if (honorific_prefix_other) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'honorific_prefix' || o.type == 'honorific_prefix_other'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'name':
        let name = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'name'; })]
        schema = validateName(id, mandatory)
        if (name) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'name'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'given_name':
        let given_name = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'given_name'; })]
        schema = validateGivenName(id, mandatory)
        if (given_name) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'given_name'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'family_name':
        let family_name = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'family_name'; })]
        schema = validateFamilyName(id, mandatory)
        if (family_name) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'family_name'; })].value  })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'email':
        let email = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'email'; })]

        if (email) {
          if (email.value.length > 0) {
            schema = isUser ? validateEmail : mandatory ? validateEmailRequired : validateEmail
          } else {
            schema = isUser ? validateEmail : mandatory ? validateEmail : validateEmail
          }
          Object.assign(initialValues, { email: email.value  })
          Object.assign(initialValues, { email_confirm: email.value  })
        } else {
          schema = isUser ? validateEmail : mandatory ? validateEmailRequired : validateEmail
          Object.assign(initialValues, { email: '' })
          Object.assign(initialValues, { email_confirm: '' })
        }
        break
      case 'tel':
        let tel = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'tel'; })]
        schema = validateTel(id, mandatory)
        if (tel) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'tel'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'bday':
        let birthdate = null
        if (openid_user_infos && openid_user_infos.birthdate && openid_user_infos.birthdate.length) {
          birthdate = openid_user_infos.birthdate.split('-')
        }
        schema = validateBirthday(id, mandatory)
        Object.assign(initialValues, { ['bday_' + id]: birthdate ? { day: birthdate[2], month: birthdate[1], year: birthdate[0], item_id: id} : { day: '', month: '', year: '', item_id: id}})
        break
      case 'street_address':
        let street_address = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'street_address'; })]
        schema = validateStreetAddress(id, mandatory)
        if (street_address) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'street_address'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'postal_code':
        let postal_code = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'postal_code'; })]
        schema = validatePostalCode(id, mandatory)
        if (postal_code) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'postal_code'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'city':
        let city = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'city'; })]
        schema = validateCity(id, mandatory)
        if (city) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'city'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'country_name':
        let country_name = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'country_name'; })]
        schema = validateCountryName(id, mandatory)
        if (country_name) {
          Object.assign(initialValues, { [id]: follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'country_name'; })].value })
        } else {
          Object.assign(initialValues, { [id]: '' })
        }
        break
      case 'customer_card_number':
        let bgnumber = null
        if (openid_user_infos && openid_user_infos.customer_card_number && openid_user_infos.customer_card_number.length) {
          bgnumber = openid_user_infos.customer_card_number.split('//')
        }
        schema = validateCustomerCardNumber(id, mandatory)
        Object.assign(initialValues, { ['customer_card_number_' + id]: bg ? { bg: bgnumber[0], bg_number: bgnumber[1], item_id: id} : { bg: '', bg_number: '', item_id: id}})
        break
      default:
        console.log(type)
    }
  }
  if (typeof schema === 'object') {
    schema_array.push(schema)
  }
}

const email_helper = (field, shadow, isUser, follow_up_invite, follow_up_user_infos, send_confirm_request_booked_as_user, custom_email_required_text, activeInputName, intl) => {
  let email = { value: '' }
  if (follow_up_user_infos) {
    email = follow_up_user_infos[_.findIndex(follow_up_user_infos, function(o) { return o.type == 'email'; })]
  }
  return (
    <Fragment key={uuidv4()}>
      { !isUser ?
        <p>
          {custom_email_required_text && custom_email_required_text.length > 0 ? <span dangerouslySetInnerHTML={createMarkup(custom_email_required_text)} /> : <span dangerouslySetInnerHTML={createMarkup(intl.formatMessage({id: 'dataforms.email_info'}))} />}
        </p> : null
      }
      <div className={styles['col-half']}>
        <Field
          name={field.type}
          as={CustomField}
          type={'email'}
          label={field.label}
          required={field.mandatory_for_users ? true : isUser && !send_confirm_request_booked_as_user ? false : field.mandatory}
          shadow={shadow ? styles.drop_shadow : null}
          readOnly={findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name) || follow_up_invite}
          activeInputName={(input_name) => activeInputName(input_name, 'email')}
        />
      </div>
      <div className={styles['col-half']}>
        <Field
          name={'email_confirm'}
          as={CustomField}
          type={'email_confirm'}
          label={field.label}
          required={field.mandatory_for_users ? true : isUser && !send_confirm_request_booked_as_user ? false : field.mandatory}
          shadow={shadow ? styles.drop_shadow : null}
          readOnly={findFieldByKey(dataFieldKeysAndValuesFromParamsArray, field.unique_name) || follow_up_invite}
          activeInputName={(input_name) => activeInputName(input_name, 'email_confirm')}
        />
      </div>
    </Fragment>
  )
}

function createMarkup(markup) {
  return { __html: markup };
}

function findFieldByKey(array, searchKey) {
  for (let obj of array) {
    if (obj.key === searchKey) {
      return obj.value;
    }
  }
  return null;
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    session_info: state.session_info,
    selected_service: state.user_selected_service,
    selected_service_name: state.user_selected_service_name,
    selected_service_data: state.user_selected_service_data,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    selected_slot_time_of_slot: state.user_selected_slot_time_of_slot,
    selected_subtask: state.user_selected_subtask,
    user_person_count: state.user_count_persons,
    selected_language: state.user_selected_language,
    booking: state.booking,
    ui: state.ui,
    appointment_times: state.appointment_time,
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(DataFormsNew)
);
