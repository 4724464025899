import { LOCALES } from '../locales'

export default {
  [LOCALES.ARABIC]: {
    "issues": {
      "browser_header": "حدد موعدًا عبر الإنترنت.",
      "what_is_cleverq": "ما هو> cleverQ <؟",
      "additional_issues": "حدد المزيد من الموضوعات"
    },
    "app": {
      "header": "ما هو> cleverQ <؟",
      "important": "المعلومات الحالية"
    },
    "persons": {
      "header": "أدخل عدد الأشخاص الذين تريد حجز موعد لهم.",
      "information": "<b style={{ color: 'red' }}>BITTE BEACHTEN SIE:</b>  يمكنك حجز موعد واحد فقط.",
      "continue": "إلى المواعيد",
      "remove": "حذف",
      "to_much_time": "عدد الطلبات والإجراءات يتجاوز وقت الاجتماع المسموح به. يرجى الاختيار."
    },
    "datepicker": {
      "header": "الرجاء تحديد اليوم المطلوب.",
      "mon": "الاثنين",
      "tue": "يوم الثلاثاء",
      "wed": "الأربعاء",
      "thu": "يوم الخميس",
      "fri": "جمعة",
      "sat": "السبت",
      "sun": "الأحد",
      "no_appointments": "للأسف ، جميع التواريخ التي حددتها محجوزة بالكامل حاليًا. الرجاء معاودة المحاولة في وقت لاحق.",
      "error": "كانت هناك مشكلة في طلب موعد. الرجاء معاودة المحاولة في وقت لاحق."
    },
    "slotpicker": {
      "header": "اختر وقتًا.",
      "no_appointments": "للأسف لا يوجد موعد لهذا اليوم.",
      "legend_green": "من الممكن تحديد موعد",
      "legend_yellow": "لم يتبق سوى عدد قليل من التواريخ",
      "legend_grey": "لا يمكن تحديد موعد",
      "timeout_reached": "انقطع الاتصال بالخادم. حاول ثانية."
    },
    "dataforms": {
      "mandatory_fields": "حقل الزامي",
      "header": "الرجاء إدخال بياناتك الشخصية.",
      "data_delete": "سيتم حذف بياناتك تلقائيًا بعد موعدك.",
      "ms": "امرأة",
      "mr": "سيد",
      "salutation": "تحياتي",
      "company": "اسم الشركة",
      "given_name": "الاسم الأول",
      "surname": "اسم العائلة",
      "birthday": "عيد ميلاد | التنسيق: DD.MM.YYYY",
      "email": "عنوان البريد الإلكتروني",
      "email_confirmation": "تكرار البريد الإلكتروني",
      "email_optional": "عنوان بريد إلكتروني إضافي",
      "email_confirmation_optional": "أعد إدخال عنوان بريد إلكتروني اختياري",
      "summary": "يكمل:",
      "phone": "رقم هاتف إضافي",
      "tel_information": " ",
      "appointment_for": " ",
      "appointment_around": " حول",
      "appointment_for_only": "ل ",
      "on": "في",
      "clock": "ساعة",
      "ident_number": "رقم العميل",
      "ident_number_text": "الرجاء إدخال رقم العميل الخاص بك حتى تتمكن من تأكيد موعدك باستخدام بطاقة العميل الخاصة بك.",
      "message": "رسالة اختيارية عن موعدك.",
      "book_appointment": "قم بإجراء حجز ملزم الآن",
      "book_appointment_not_valid": "يرجى ملء جميع الحقول المطلوبة",
      "book_appointment_started": "تم حجز الموعد ، برجاء الإنتظار ...",
      "book_appointment_failed": "فشل حجز الموعد ، الرجاء الضغط مرة أخرى.",
      "terms_and_conditions": "سياسة الخصوصية",
      "terms_and_conditions_text_for_confirm": "باستخدام خدمة التسجيل عبر الإنترنت، فإنك توافق على معالجة بياناتك الشخصية. <br/><br/> يتم تخزين جميع البيانات الشخصية فقط لغرض معالجة السجلات.",
      "terms_and_conditions_link": "رابط لسياسة الخصوصية",
      "email_info": "لتتمكن من إرسال تأكيد التسجيل والمستندات الضرورية الأخرى عبر البريد الإلكتروني، يجب عليك إدخال عنوان بريدك الإلكتروني</br>. إذا لم تشر إلى ذلك، فإن التسجيل عبر الإنترنت غير ممكن للأسف.",
      "mobile_phone_number": "رقم الهاتف الجوال",
      "sms_notifications_label": "إشعار الرسائل القصيرة",
      "sms_notifications_checkbox_text": "أرغب في أن يتم إعلامي بموعدي عبر إشعار الرسائل القصيرة.",
      "sms_notifications_text": "لن نتصل بك ولن نستخدم رقم هاتفك المحمول بأي طريقة أخرى.",
      "back": "خلف",
      "cancel": "ينهي",
      "close_privacy_policy": "إغلاق سياسة الخصوصية",
      "confirm_policy": "يرجى تأكيد سياسة الخصوصية.",
      "policy": "لقد قرأت معلومات حماية البيانات وأوافق على معالجة بياناتي.",
      "show_policy": "عرض سياسة الخصوصية",
      "optional": "(اختياري)"
    },
    "dataforms_waiting_list_entry": {
      "header": "قم بالتسجيل في قائمة الانتظار",
      "waiting_list_entry_not_valid": "يرجى ملء جميع الحقول المطلوبة",
      "waiting_list_entry": "سجل الان بدون التزام",
      "waiting_list_entry_started": "التسجيل في قائمة الانتظار ، برجاء الانتظار ...",
      "go_to_waiting_list_entry": "متابعة التسجيل في قائمة الانتظار",
      "successfull_registered": "لقد تلقينا تسجيلك. للقيام بذلك ، يرجى النقر فوق الارتباط الموجود في البريد الإلكتروني الذي أرسلناه لك.",
      "register_waiting_list_entry": "طلب التسجيل"
    },
    "forms": {
      "bday": {
        "day": "يوم - نهار",
        "month": "شهر",
        "year": "سنة"
      },
      "email_confirm": "يكرر",
      "honorific_prefix": {
        "mister": "سيد",
        "miss": "امرأة",
        "other": "متنوع"
      },
      "validations": {
        "default": {
          "required": "هذه المعلومات مطلوبة.",
          "min": "أدخل حرفين على الأقل.",
          "max": "عدد الأحرف كبير جدًا.",
          "letter_only": "يسمح فقط بالحروف في هذا المجال.",
          "numbers_only": "يسمح فقط بالأرقام في هذا المجال."
        },
        "number": {
          "min": "الرجاء إدخال رقم أكبر من 0.",
          "max": "الرجاء إدخال رقم أقل من 1،000،000."
        },
        "email": {
          "required": "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك.",
          "not_matching": "عناوين البريد الإلكتروني غير متطابقة.",
          "not_a_valid_email": "يرجى إدخال عنوان بريد إلكتروني صالح."
        },
        "options": {
          "required": "الرجاء تحديد خيار."
        },
        "name": {
          "required": "من فضلك أدخل إسمك."
        },
        "given_name": {
          "required": "من فضلك أدخل إسمك."
        },
        "family_name": {
          "required": "أدخل اسمك الأخير."
        },
        "honorific_prefix": {
          "required": "تحياتي"
        },
        "street_address": {
          "required": "أدخل شارعك."
        },
        "postal_code": {
          "required": "أدخل رمزك البريدي."
        },
        "city": {
          "required": "أدخل مكان إقامتك."
        },
        "country_name": {
          "required": "أدخل بلدك الأصلي."
        },
        "bday": {
          "day": {
            "required": "الرجاء إدخال تاريخ ميلادك في التقويم.",
            "type_error": "أدخل قيمة بين 1 و 31.",
            "min": "هذا يوم تقويم غير صالح.",
            "max": "هذا يوم تقويم غير صالح."
          },
          "month": {
            "required": "الرجاء إدخال شهر ميلادك.",
            "type_error": "الرجاء إدخال قيمة بين 1 و 12.",
            "min": "هذا ليس شهر تقويمي صالح.",
            "max": "هذا ليس شهر تقويمي صالح."
          },
          "year": {
            "required": "الرجاء إدخال السنة التقويمية لتاريخ ميلادك.",
            "type_error": ". ${year} أدخل قيمة بين 1880 و ",
            "min": "أدخل قيمة بين 1880 و 2020.",
            "max": "أدخل قيمة بين 1880 و 2020."
          }
        },
        "sex": {
          "required": "الرجاء إدخال جنسك"
        },
        "tel": {
          "required": "يرجى إدخال رقم الهاتف الخاص بك.",
          "not_valid": "يرجى إدخال رقم هاتف صالح."
        },
        "terms_and_conditions": "يرجى تأكيد سياسة الخصوصية الخاصة بنا.",
        "ident_number.required": "الرجاء إدخال رقم العميل الخاص بك."
      }
    },
    "appointment_info": {
      "at": "في",
      "location": "موقع",
      "header": "ملخص موعدك",
      "title": "تم تحديد موعدك.",
      "title_reserved": "لم يتم تأكيد اجتماعهم بعد.",
      "title_on_site_reserved": "تم تحديد موعدك. مطلوب تأكيد في الموقع.",
      "title_desk_reserved": "تم تحديد موعدك. مطلوب موافقة الموظف.",
      "infotext": "<h4> دفع الإشعارات على هاتفك الذكي </h4><p> امسح رمز الاستجابة السريعة ضوئيًا باستخدام تطبيق cleverQ على هاتفك الذكي وتلقي تذكيرات مناسبة لحجز موعدك عبر إشعار الدفع.</p>",
      "confirmtext": "<h4> ملاحظات حول تأكيد الموعد </h4><p>  لقد حجزت موعدًا معنا. من أجل حجز هذا بشكل ملزم ، نحتاج إلى تأكيد في البريد الإلكتروني المرسل إليك. الرجاء الضغط على الرابط في هذا البريد الإلكتروني. أنت بموجب هذا تحجز موعدًا ملزمًا معنا. </p>  <p> بدون هذه العملية ، تنتهي صلاحية الحجز.</p>",
      "on_site_confirmtext": "<h4> التأكيد في الموقع </h4><p> يُرجى تذكر إحضار هذا المستند معك إلى موعدك إما في شكل رقمي على هاتفك الذكي أو في شكل ورقي مطبوع. تحتاج إما إلى رمز الحجز أو رمز الاستجابة السريعة لتأكيد موعدك في الموقع ثم الاحتفاظ به.</p>",
      "desk_confirmtext": "<h4> تأكيد الموعد من قبل أحد الموظفين </h4><p>  لقد أرسلنا طلب موعدك إلى أحد الموظفين. بمجرد تأكيد الموظف للموعد ، ستتلقى رسالة تأكيد عبر البريد الإلكتروني.</p>",
      "reservation_code": "كود الحجز",
      "summary": "ملخص",
      "issue": "انتاج",
      "appointment": "يقابل",
      "persons": "الأشخاص.",
      "person": "شخص",
      "name": "اسم العائلة",
      "birthday": "عيد ميلاد",
      "email": "عنوان البريد الإلكتروني",
      "phone": "رقم التليفون",
      "message": "علاقة",
      "back_button": "العودة إلى المزود",
      "print_button": "الصحافة",
      "advertising": "<h4> cleverQ - تطبيق الهاتف الذكي </h4><p> قم بتنزيل تطبيقنا المجاني cleverQ واستمتع بمزايا هاتفك الذكي. <br/> احجز المواعيد وشراء التذاكر ودفع الإخطارات وكل شيء أثناء التنقل. لذا فأنت دائمًا على اطلاع.</p>",
      "advertising_image": "/images/smartphone.png",
      "duplicate_info_title": "الموعد مكرر",
      "duplicate_info_title_remove": "الرجاء إلغاء موعدك القديم",
      "duplicate_info_text": "لقد قمت بالفعل بحجز موعد. يرجى إلغاء موعدك القديم قبل حجز موعد جديد. يمكنك إلغاء الموعد القديم عن طريق التحقق من البريد الإلكتروني المرسل إليك مع موعدك القديم.",
      "slot_already_booked_title": "حجز موعد غير ممكن",
      "slot_already_booked_text": "للأسف ، تم حجز التاريخ الذي طلبته من قبل شخص آخر. نأسف للإزعاج ونرجو تحديد موعد آخر."
    },
    "navigation": {
      "cleverq": "لأن من يحب الانتظار؟",
      "legal_notice": "بصمة",
      "policy": "حماية البيانات"
    },
    "greetings": {
      "confirm_greetings": "الشروع في حجز موعد"
    },
    "checkout_title": {
      "your": "ها",
      "choice": "اختيار"
    },
    "enter_invite_code": {
      "verify": "للتحقق",
      "invite_code":"شفرة الدعوة"
    },
    "appointment_times": {
      "at": "في ال ",
      "on": "حول ",
      "o_clock": "ساعة",
      "header": "وقت موعدك المحجوز.",
      "remove": "اختر موعدًا جديدًا",
      "session": {
        "text": "تنتهي جلستك في: ",
        "close_error_modal": "يغلق",
        "reservation_expired": "انتهى الحجز ، يرجى اختيار موعد جديد.",
        "expired": "انتهت صلاحية جلستك لأنك كنت غير نشط لفترة طويلة. رجاء أعد تحميل الصفحة."
      }
    }
  }
}
