import { LOCALES } from '../locales'

export default {
    [LOCALES.NORWEGIAN]: {
        "issues": {
            "browser_header": "Bare bestill møtet ditt online.",
            "what_is_cleverq": "Hva er cleverQ?",
            "additional_issues": "Velg flere bekymringer"
        },
        "app": {
            "header": "Hva er cleverQ?",
            "important": "Aktuell informasjon"
        },
        "persons": {
            "header": "Vennligst oppgi hvor mange personer du ønsker å booke et møte for.",
            "information": "<b style={{ color: 'red' }}>MERK:</b> Du kan bare bestille ett møte. Selv om du både må sende inn en søknad og søke om oppfølgingsmøte. Selv om du kun kan bestille ett møte, vil du bli tatt hånd om i begge saker.",
            "continue": "Fortsett til møtevalg",
            "remove": "Fjerne",
            "to_much_time": "Antall saker og prosesser overskrider den tillatte møtevarigheten. Reduser utvalget."
        },
        "datepicker": {
            "header": "Velg ønsket dag.",
            "mon": "Man.",
            "tue": "Tirs.",
            "wed": "Ons.",
            "thu": "Tors.",
            "fri": "Fre.",
            "sat": "Lør.",
            "sun": "Søn.",
            "no_appointments": "Dessverre er alle møter fullbooket for ditt valg. Prøv igjen senere.",
            "error": "Det oppsto et problem med å be om møtet. Prøv igjen senere."
        },
        "slotpicker": {
            "header": "Velg ønsket tidspunkt.",
            "no_appointments": "Dessverre er det ingen møter tilgjengelig denne dagen.",
            "legend_green": "Møter tilgjengelig",
            "legend_yellow": "Kun noen få møter igjen",
            "legend_grey": "Ingen møter tilgjengelig",
            "timeout_reached": "Tilkoblingen til serveren ble brutt. Prøv igjen."
        },
        "dataforms": {
            "ident_number": "Customer number",
            "ident_number_text": "Please enter your customer number so that you can confirm your appointment on site with your customer card.",
            "mandatory_fields": "Obligatoriske felt",
            "header": "Vennligst skriv inn dine personlige data nå.",
            "data_delete": "Dataene dine blir automatisk slettet av cleverQ etter møtet.",
            "ms": "Ms.",
            "mr": "Mr.",
            "salutation": "Adressetittel",
            "company": "Selskapsnavn",
            "given_name": "Fornavn",
            "surname": "Etternavn",
            "birthday": "Bursdag | Format: dd.mm.åååå",
            "email": "Epostadresse",
            "email_confirmation": "Gjenta e-postadresse",
            "email_optional": "Ytterligere e-postadresse",
            "email_confirmation_optional": "Gjenta ytterligere e-postadresse",
            "summary": "Sammendrag:",
            "phone": "Valgfritt telefonnummer",
            "tel_information": " ",
            "appointment_for": " ",
            "appointment_around": " klokken",
            "appointment_for_only": "til ",
            "on": "på",
            "clock": "klokken",
            "message": "Valgfri melding om møtet ditt.",
            "book_appointment": "Gjør en bindende reservasjon for møtet nå",
            "book_appointment_not_valid": "Vennligst fyll ut alle merkede obligatoriske felt",
            "book_appointment_started": "Møtet er booket, vennligst vent...",
            "book_appointment_failed": "Møtebestilling mislyktes. Klikk på nytt.",
            "terms_and_conditions": "Personvernerklæring",
            "terms_and_conditions_text_for_confirm": "Ved å bruke online møtebestillingstjeneste godtar du at personopplysningene du oppgir vil bli behandlet av oss. <br/> <br/> Alle personopplysninger vil kun bli lagret med det formål å behandle møtet.",
            "terms_and_conditions_link": "Link til personvernerklæring",
            "email_info": "For at møtebekreftelsen og eventuelle andre nødvendige dokumenter kan sendes til deg på e-post, <br/> må du skrive inn e-postadressen din. Dersom du ikke oppgir dette, er det dessverre ikke mulig å bruke nettmøteavtalen.",
            "mobile_phone_number": "Mobilnummer",
            "sms_notifications_label": "SMS-varsler",
            "sms_notifications_checkbox_text": "Jeg vil gjerne holdes oppdatert om avtalen min via SMS-varsler.",
            "sms_notifications_text": "Vi vil ikke ringe deg, og mobilnummeret ditt vil heller ikke bli brukt til andre formål.",
            "back": "Komme tilbake",
            "cancel": "Avbryte",
            "close_privacy_policy": "Lukk personvernerklæring",
            "confirm_policy": "Please confirm the privacy policy",
            "policy": "I have read the data protection information and agree to my data being processed.",
            "show_policy": "Show privacy policy",
            "optional": "(valgfritt)"
        },
        "dataforms_waiting_list_entry": {
            "header": "Påmelding til venteliste",
            "waiting_list_entry_not_valid": "Vennligst fyll ut alle merkede obligatoriske felt",
            "waiting_list_entry": "Registrer deg uforpliktende nå",
            "waiting_list_entry_started": "Ventelisteregistrering pågår, vennligst vent...",
            "go_to_waiting_list_entry": "Gå videre til ventelisteregistrering",
            "successfull_registered": "Vi har mottatt din påmelding. For at dette skal tre i kraft, vennligst klikk på lenken i e-posten vi sendte deg.",
            "register_waiting_list_entry": "Forespørsel om registrering"
        },
        "forms": {
            "bday": {
                "day": "Dag",
                "month": "Måned",
                "year": "År"
            },
            "email_confirm": "å gjenta",
            "honorific_prefix": {
                "mister": "Mr.",
                "miss": "Mrs.",
                "other": "Annen"
            },
            "validations": {
                "default": {
                    "required": "Denne informasjonen er nødvendig.",
                    "min": "Skriv inn minst to tegn.",
                    "max": "For mange karakterer.",
                    "letter_only": "Kun bokstaver er tillatt i dette feltet.",
                    "numbers_only": "Bare tall er tillatt i dette feltet."
                },
                "number": {
                    "min": "Vennligst skriv inn et tall som er større enn 0.",
                    "max": "Angi et tall som er mindre enn 1000000."
                },
                "email": {
                    "required": "Vennligst skriv inn E-postadressen din.",
                    "not_matching": "E-postadressene stemmer ikke overens.",
                    "not_a_valid_email": "Vennligst skriv inn en gyldig e-post adresse."
                },
                "options": {
                    "required": "Vennligst velg et alternativ."
                },
                "name": {
                    "required": "Vennligst skriv inn navnet ditt."
                },
                "given_name": {
                    "required": "Vennligst skriv inn fornavnet ditt."
                },
                "family_name": {
                    "required": "Vennligst fyll inn ditt etternavn."
                },
                "honorific_prefix": {
                    "required": "Vennligst skriv inn en tittel."
                },
                "street_address": {
                    "required": "Skriv inn gateadressen din."
                },
                "postal_code": {
                    "required": "Vennligst skriv inn postnummeret ditt."
                },
                "city": {
                    "required": "Skriv inn navnet på byen din."
                },
                "country_name": {
                    "required": "Skriv inn landet ditt."
                },
                "bday": {
                    "day": {
                        "required": "Vennligst skriv inn kalenderdagen for bursdagen din.",
                        "type_error": "Angi en verdi mellom 1 og 31.",
                        "min": "Dette er ikke en gyldig kalenderdag.",
                        "max": "Dette er ikke en gyldig kalenderdag."
                    },
                    "month": {
                        "required": "Vennligst skriv inn kalendermåneden for bursdagen din.",
                        "type_error": "Skriv inn en verdi mellom 1 og 12.",
                        "min": "Dette er ikke en gyldig kalendermåned.",
                        "max": "Dette er ikke en gyldig kalendermåned."
                    },
                    "year": {
                        "required": "Vennligst skriv inn kalenderåret for bursdagen din.",
                        "type_error": "Angi en verdi mellom 1880 og ${year}.",
                        "min": "Angi en verdi mellom 1880 og 2020.",
                        "max": "Angi en verdi mellom 1880 og 2020."
                    }
                },
                "card": {
                    "bg": {
                        "required": "Vennligst skriv inn venstre del av BG-nummeret ditt.",
                        "type_error": "Vennligst skriv inn en verdi med 5 tall.",
                        "min": "Dette er ikke en gyldig verdi for venstre del av BG-nummeret.",
                        "max": "Dette er ikke en gyldig verdi for venstre del av BG-nummeret."
                    },
                    "bg_number": {
                        "required": "Vennligst skriv inn høyre del av BG-nummeret ditt.",
                        "type_error": "Vennligst skriv inn en verdi med 7 sifre for den høyre delen av BG-nummeret.",
                        "min": "Dette er ikke en gyldig verdi for høyre del av BG-nummeret.",
                        "max": "Dette er ikke en gyldig verdi for høyre del av BG-nummeret."
                    }
                },
                "sex": {
                    "required": "Vennligst skriv inn kjønnet ditt."
                },
                "tel": {
                    "required": "Vennligst skriv inn telefonnummeret ditt.",
                    "not_valid": "Vennligst skriv inn et gyldig telefonnummer."
                },
                "terms_and_conditions": "Bekreftelse av integritetspolicy.",
                "ident_number.required": "Please enter your customer number."
            }
        },
        "appointment_info": {
            "at": "I",
            "location": "Sted",
            "header": "Ditt møteoppsummering",
            "title": "Møtet ditt er booket.",
            "title_reserved": "Møtet ditt er ennå ikke bekreftet.",
            "title_on_site_reserved": "Møtet ditt er reservert. Bekreftelse på stedet kreves.",
            "title_desk_reserved": "Møtet ditt er reservert. Ansattes godkjenning kreves.",
            "infotext": "<h4>Pushvarsling på smarttelefonen din</h4><p> Skann QR-koden med vår cleverQ-app på smarttelefonen din og motta praktiske påminnelser om å bestille møtet ditt via pushvarsling.</p>",
            "confirmtext": "<h4>Merknader om møtebekreftelse</h4><p> Du har reservert et møte med oss. For å reservere dette bindende trenger vi en bekreftelse i e-posten sendt til deg. Vennligst klikk på lenken i denne e-posten. Du bestiller herved et bindende møte med oss.</p><p>${achtung} uten denne prosessen utløper reservasjonen.</p>",
            "on_site_confirmtext": "<h4>Bekreftelse på stedet</h4><p> Husk å ta med dette dokumentet til møtet enten i digital form på smarttelefonen eller i trykt papirform. Du trenger enten reservasjonskoden eller QR-koden for å bekrefte møtet ditt på stedet og deretter delta på det.</p>",
            "desk_confirmtext": "<h4>møtebekreftelse fra en medarbeider</h4><p> Vi har videresendt møteforespørselen din til en medarbeider. Så snart møtet er bekreftet av den ansatte vil du motta en bekreftelsesmail</p>",
            "reservation_code": "Reservasjons kode",
            "summary": "Sammendrag",
            "issue": "Kategori",
            "appointment": "Møte",
            "persons": "Mennesker.",
            "person": "Person",
            "name": "Navn",
            "birthday": "Bursdag",
            "email": "Epostadresse",
            "phone": "Telefonnummer",
            "message": "Beskjed",
            "back_button": "Tilbake til leverandøren",
            "print_button": "Skrive ut",
            "advertising": "<h4>cleverQ - Smartphone-app</h4> <p>Last ned vår gratis cleverQ-app og bruk fordelene med smarttelefonen din. <br/> Bestill møter, kjøp billetter, push-varsler, alt mens du er på farten. Så du er alltid oppdatert.</p>",
            "advertising_image": "/images/smartphone.png",
            "duplicate_info_title": "Duplikat av møtet",
            "duplicate_info_title_remove": "Vennligst avbryt ditt gamle møte",
            "duplicate_info_text": "Du har allerede bestilt et møte. Vennligst avbryt ditt gamle møte før du bestiller et nytt. Du kan avbryte det gamle møtet ved å sjekke e-posten som ble sendt til deg med det gamle møtet.",
            "slot_already_booked_title": "Møtebestilling ikke mulig",
            "slot_already_booked_text": "Dessverre er datoen du ba om, blitt bestilt av noen andre i mellomtiden. Vi beklager ulempene og ber deg foreta et nytt møtevalg."
        },
        "navigation": {
            "cleverq": "For hvem liker å vente?",
            "legal_notice": "Vilkår og betingelser",
            "policy": "Personvernerklæring"
        },
        "greetings": {
            "confirm_greetings": "Gå til møtebestilling"
        },
        "checkout_title": {
            "your": "Din",
            "choice": "Valg"
        },
        "enter_invite_code": {
            "verify": "Å godkjenne",
            "invite_code":"invitasjonskode"
        },
        "appointment_times": {
            "at": "På ",
            "on": "På ",
            "o_clock": "Klokken",
            "header": "Din reserverte møtetid.",
            "remove": "Velg et nytt møtetidspunkt",
            "session": {
                "text": "Økten din vil utløpe dersom: ",
                "close_error_modal": "Lukk",
                "reservation_expired": "Reservasjonen er utløpt, velg et nytt tidspunkt.",
                "expired": "Økten din har utløpt fordi du har vært inaktiv for lenge. Last inn siden på nytt."
            }
        }
    }
}