import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import * as actions from '../../actions';
import moment from 'moment';
import { Default } from './elements/Checkout'
import {BarLoader, ScaleLoader} from "react-spinners";
import Modal from "react-modal";
import { injectIntl } from "react-intl";
import {scrollToTarget} from "./elements/ScrollToTarget";

const query = new URLSearchParams(window.location.search);
const touchscreen_mode = query.get('touchscreen_mode') === 'true' ? true : false;

class Checkout extends Component {
  constructor() {
    super();

    this.state = {
      disabled: false,
      show_error_modal: false,
      modal_error_message: false
    }
  }
  closeErrorModal = () => {
    window.location.reload();
  }
  handleClick() {
    // Range for getting available appointmentdays.
    let from_day;
    let to_day;

    if (this.props.ui.validated && this.props.ui.validated.not_booking_before) {
      from_day = moment(this.props.ui.validated.not_booking_before).format('YYYY-MM-DD');
      to_day = moment(this.props.ui.validated.expiration_date).add(1, 'days').format('YYYY-MM-DD');
    } else {
      from_day = moment().format('YYYY-MM-DD');
      to_day = moment()
        .add(52, 'weeks')
        .endOf('week')
        .format('YYYY-MM-DD');
    }
    if (!this.state.disabled) {
      this.setState({ disabled: true })
      //this.props.setResetDayEdit();
      this.props.weekChange();
      //this.props.afterPersonsSelected(false);
      this.props.fetchAppointmentDays(
        this.props.site_info.id,
        this.props.selected_service,
        from_day,
        to_day,
        this.props.no_issues ? 'no_issues' : this.props.user_person_count
      ).then((response) => {
        if (response.payload && response.payload.code !== 1012 && response.payload.code !== 1013 ) {
          if (!touchscreen_mode) {
            setTimeout(() => {
              this.setState({ disabled: false })
              this.props.afterPersonsSelected(true);
              this.props.setDayEdit();
              scrollToTarget("scroll_target_pickup_day")
            }, process.env.NODE_ENV === 'production' ? Math.floor(Math.random() * (2500 - 1000) + 1000) : 0);
          } else {
            this.setState({ disabled: false })
            this.props.afterPersonsSelected(true);
            this.props.setDayEdit();
          }
        } else {
          this.setState({show_error_modal: true, modal_error_message: response.payload.message, disabled: false})
        }
      })
    }
  }
  render() {
    const {intl} = this.props
    const modalCustomStyles = {
      content : {
        top                   : '45%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        width                 : '50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 110
      },
    };
    return (
      !this.props.appointment_times.length ?
        <div
          className={`container animated fadeInLeft`}>
          <div
            className="row">
            <div>
              {this.props.timeFrameExceeded === 0 ? (
                !this.state.disabled ?
                  <div onClick={this.handleClick.bind(this)}>
                    <Default site_info={this.props.site_info} text={intl.formatMessage({id: "persons.continue", icon_type: "continue_button"})} />
                  </div> : null

              ) : (
                <div
                  className={`${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}
                  style={{
                    marginTop: '10px',
                    fontSize: '1.7rem',
                    padding: '25px',
                    border: '1px solid #CBCBCBFF',
                    fontWeight: '600',
                    textAlign: 'center',
                    backgroundColor: this.props.site_info
                      .delete_button_background,
                    color: this.props.site_info.delete_button_text
                  }}>
                  {intl.formatMessage({id: "persons.to_much_time"})}
                </div>
              )}
            </div>
          </div>
          <Modal
            isOpen={this.state.disabled}
            ariaHideApp={false}
            style={modalCustomStyles}
          >
            <BarLoader
              sizeUnit={'px'}
              height={30}
              width={'100%'}
              color={this.props.site_info.loading_indicator}
              loading={true}
            />
          </Modal>
          <Modal
            isOpen={this.state.show_error_modal}
            onRequestClose={this.closeErrorModal.bind(this)}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={modalCustomStyles}
          >
            <span style={{fontWeight: 'bold', color: 'red', fontSize: '1.6rem'}}>{intl.formatMessage({id: 'appointment_times.session.expired'})}</span><br/>

            <button
              className={`btn ${
                this.props.site_info.drop_shadows ? 'drop-shadow' : null
              }`}
              onClick={this.closeErrorModal}
              style={{
                marginTop: '10px',
                background: this.props.site_info.confirm_button_background,
                color: this.props.site_info.confirm_button_text,
                border: '1px solid #CBCBCBFF'
              }}>
              <span>{intl.formatMessage({id: 'appointment_times.session.close_error_modal'})}</span>
            </button>
          </Modal>
        </div> : null
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_subtask: state.user_selected_subtask,
    selected_day: state.user_selected_day,
    user_person_count: state.user_count_persons,
    no_issues: state.no_issues,
    ui: state.ui,
    appointment_times: state.appointment_time
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Checkout)
);
