import { LOCALES } from '../locales'

export default {
  [LOCALES.TURKISH]: {
    "issues": {
      "browser_header": "Randevunuzu çevrimiçi yapın.",
      "what_is_cleverq": ">cleverQ< nedir?",
      "additional_issues": "Ek konular seçin"
    },
    "app": {
      "header": ">cleverQ< nedir?",
      "important": "güncel bilgiler"
    },
    "persons": {
      "header": "Lütfen kaç kişi için randevu almak istediğinizi belirtiniz.",
      "information": "<b style={{ color: 'red' }}>PLEASE NOTE:</b> Hem başvuru yapmanız hem de yeniden giriş izni için başvurmanız gerekse bile yalnızca bir randevu alabilirsiniz. Sadece bir randevu alabilmenize rağmen, her iki kategoride de hizmet alacaksınız.",
      "continue": "Randevu seçimine devam et",
      "remove": "iptal etmek",
      "to_much_time": "İstek ve eylem sayısı, izin verilen randevu süresini aşıyor. Lütfen seçiminizi azaltın."
    },
    "datepicker": {
      "header": "İstediğiniz günü seçin.",
      "mon": "Pazartesi",
      "tue": "Salı",
      "wed": "Çarşamba",
      "thu": "Perşembe",
      "fri": "Cuma",
      "sat": "Cumartesi",
      "sun": "Pazar",
      "no_appointments": "Ne yazık ki, seçtiğiniz tüm tarihler şu anda tamamen dolu. Lütfen daha sonra tekrar deneyiniz.",
      "error": "Randevu talep edilirken bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz."
    },
    "slotpicker": {
      "header": "Lütfen zamanı seçin.",
      "no_appointments": "Ne yazık ki bu gün için bir giriş yok.",
      "legend_green": "Randevu mümkün",
      "legend_yellow": "Sadece birkaç tarih kaldı",
      "legend_grey": "Randevu yok",
      "timeout_reached": "Sunucuyla bağlantı kesildi. Tekrar dene."
    },
    "dataforms": {
      "mandatory_fields": "Zorunlu alan",
      "header": "Lütfen kişisel verilerinizi giriniz.",
      "data_delete": "Verileriniz randevu sonrasında otomatik olarak silinecektir.",
      "ms": "Kadınlar",
      "mr": "bayım",
      "salutation": "selamlama",
      "company": "İşletme",
      "given_name": "İlk adı",
      "first_name": "İlk isim",
      "name": "Soyadı",
      "surname": "soyadı",
      "birthday": "doğum günü | Biçim: GG.AA.YYYY",
      "email": "e-posta adresi",
      "email_confirmation": "e-mail adresini tekrar et",
      "email_optional": "İsteğe bağlı e-posta adresi",
      "email_confirmation_optional": "İsteğe bağlı e-posta adresini tekrarlayın",
      "summary": "Özet:",
      "phone": "İsteğe bağlı telefon numarası",
      "tel_information": " ",
      "appointment_for": " ",
      "appointment_around": " etrafında",
      "appointment_for_only": "için ",
      "on": "de",
      "clock": "Saat",
      "ident_number": " ",
      "ident_number_text": " ",
      "message": "Toplantınız hakkında ek not.",
      "book_appointment": "Şimdi bağlayıcı bir rezervasyon yapın",
      "book_appointment_not_valid": "Lütfen tüm gerekli alanları doldurunuz",
      "book_appointment_started": "Yer ayırtıldı, lütfen bekleyin...",
      "book_appointment_failed": "Giriş başarısız oldu. Lütfen tekrar tıklayın.",
      "terms_and_conditions": "Gizlilik Politikası",
      "terms_and_conditions_text_for_confirm": "Çevrimiçi randevu rezervasyon hizmetini kullanarak sağladığınız kişisel verilerin işlenmesini kabul etmiş olursunuz. Tüm kişisel veriler yalnızca randevuların işlenmesi amacıyla saklanır.",
      "terms_and_conditions_link": "Gizlilik politikasına bağlantı",
      "email_info": "Randevu onayı ve diğer gerekli belgelerin tarafınıza e-posta ile gönderilebilmesi için. e-posta adresinizi girmeniz gerekmektedir. Bunu belirtmediğiniz takdirde online randevu maalesef mümkün değildir.",
      "mobile_phone_number": "Cep telefonu numarası",
      "sms_notifications_label": "SMS-Bildirimleri",
      "sms_notifications_checkbox_text": "Randevum hakkında SMS bildirimleri yoluyla bilgilendirilmek istiyorum.",
      "sms_notifications_text": "Sizi aramayacağız ve cep telefonu numaranız başka bir amaç için kullanılmayacaktır.",
      "back": "Geri",
      "cancel": "İptal etmek",
      "close_privacy_policy": "Gizlilik politikasından çık",
      "confirm_policy": "Lütfen gizlilik politikasını onaylayın",
      "policy": "Veri koruma bilgilerini okudum ve verilerimin işlenmesini kabul ediyorum.",
      "show_policy": "Gizlilik politikasını göster",
      "optional": "(isteğe bağlı)"
    },
    "dataforms_waiting_list_entry": {
      "header": "bekleme listesi için kaydolun",
      "waiting_list_entry_not_valid": "Lütfen tüm gerekli alanları doldurunuz",
      "waiting_list_entry": "Şimdi yükümlülük olmadan kaydolun",
      "waiting_list_entry_started": "Bekleme listesi rezervasyonu devam ediyor, lütfen bekleyin...",
      "go_to_waiting_list_entry": "Kayıt işlemine devam edin",
      "successfull_registered": "Kaydınızı aldık. Bunun yürürlüğe girmesi için lütfen size gönderdiğimiz e-postadaki bağlantıya tıklayın.",
      "register_waiting_list_entry": "kayıt iste"
    },
    "forms": {
      "bday": {
        "day": "Gün",
        "month": "ay",
        "year": "Yıl"
      },
      "email_confirm": "tekrarlamak",
      "honorific_prefix": {
        "mister": "bayım",
        "miss": "Kadınlar",
        "other": "Diğer"
      },
      "validations": {
        "default": {
          "required": "Bu bilgi gereklidir.",
          "min": "Lütfen en az iki karakter girin.",
          "max": "Çok fazla karakter.",
          "letter_only": "Bu alanda sadece harflere izin verilir.",
          "numbers_only": "Bu alanda yalnızca sayılara izin verilir."
        },
        "number": {
          "min": "Lütfen 0'dan büyük bir sayı girin.",
          "max": "100.000'den küçük bir sayı girin."
        },
        "email": {
          "required": "Lütfen e-mail adresinizi giriniz.",
          "not_matching": "E posta adresleri uyuşmuyor.",
          "not_a_valid_email": "Geçerli bir e."
        },
        "options": {
          "required": "Bir seçenek seçin."
        },
        "name": {
          "required": "Lütfen adınızı giriniz."
        },
        "given_name": {
          "required": "Lütfen adınızı giriniz."
        },
        "family_name": {
          "required": "Lütfen soyadınızı giriniz."
        },
        "honorific_prefix": {
          "required": "Lütfen bir selamlama seçin."
        },
        "street_address": {
          "required": "Lütfen sokağınızı giriniz."
        },
        "postal_code": {
          "required": "Posta kodunuzu girin."
        },
        "city": {
          "required": "İkamet ettiğiniz yeri girin."
        },
        "country_name": {
          "required": "Menşe ülkenizi girin."
        },
        "bday": {
          "day": {
            "required": "Lütfen doğum gününüzün takvim gününü giriniz.",
            "type_error": "Lütfen 1 ile 31 arasında bir değer giriniz.",
            "min": "Bu geçerli bir takvim günü değil.",
            "max": "Bu geçerli bir takvim günü değil."
          },
          "month": {
            "required": "Doğduğunuz ayı giriniz.",
            "type_error": "1 ile 12 arasında bir değer girin.",
            "min": "Bu geçersiz bir takvim ayıdır.",
            "max": "Bu geçersiz bir takvim ayıdır."
          },
          "year": {
            "required": "Lütfen doğum tarihinizin takvim yılını giriniz.",
            "type_error": "1880 ile ${year} arasında bir değer girin.",
            "min": "1880 ile 2020 arasında bir değer girin.",
            "max": "1880 ile 2020 arasında bir değer girin."
          }
        },
        "sex": {
          "required": "Lütfen cinsiyetinizi girin"
        },
        "tel": {
          "required": "Lütfen bir telefon numarası girin.",
          "not_valid": "Lütfen geçerli bir telefon numarası giriniz."
        },
        "terms_and_conditions": "Gizlilik politikamızı onaylayın.",
        "ident_number.required": "Lütfen müşteri numaranızı giriniz."
      }
    },
    "appointment_info": {
      "at": "içinde",
      "location": "Konum",
      "header": "randevunuzun özeti",
      "title": "randevunuzun özeti",
      "title_reserved": "Randevunuz henüz onaylanmadı.",
      "title_on_site_reserved": "Randevunuz alınmıştır. Yerinde onay gerekli.",
      "title_desk_reserved": "Randevunuz alınmıştır. Çalışan onayı gerekli.",
      "infotext": "<h4>Akıllı telefonunuzda push bildirimi</h4> <p> Akıllı telefonunuzdaki smartQ uygulamamızla QR kodunu tarayın ve push bildirimleriyle pratik randevu hatırlatıcıları alın. </p>",
      "confirmtext": "<h4>Randevu onay notları</h4> <p> Bizden bir randevu aldınız. Bunu ayırtmak için size gönderilen e-postada bir onaya ihtiyacımız var. Lütfen bu e-postadaki bağlantıya tıklayın. Burada bizimle bağlayıcı bir randevu alıyorsunuz.</p><p>${achtung} Bu işlem yapılmadan rezervasyon sona erer.</p>",
      "on_site_confirmtext": "<h4>Yerinde onay</h4> <p> Lütfen bu belgeyi randevunuza gelirken akıllı telefonunuzdaki dijital formda veya basılı kağıt formda yanınızda getirmeyi unutmayın. Randevunuzu yerinde onaylamak ve sonra saklamak için rezervasyon koduna veya QR koduna ihtiyacınız vardır. </p>",
      "desk_confirmtext": "<h4>Bir çalışan tarafından randevu onayı</h4> <p> Randevu talebinizi bir çalışana ilettik. Randevu çalışan tarafından onaylanır onaylanmaz bir onay e-postası alacaksınız. </p>",
      "reservation_code": "Rezervasyon kodu",
      "summary": "Özet",
      "issue": "Sorun",
      "appointment": "toplantı",
      "persons": "Kişiler.",
      "person": "Bir kişi",
      "name": "Soyadı",
      "birthday": "Doğum günü",
      "email": "e-posta adresi",
      "phone": "telefon numarası",
      "message": "iletişim",
      "back_button": "Sağlayıcıya geri dön",
      "print_button": "Basmak",
      "advertising": "<h4> cleverQ - Akıllı Telefon Uygulaması</h4> <p> Ücretsiz intelligentQ uygulamamızı indirin ve akıllı telefonunuzun avantajlarından yararlanın. <br/> Randevu alın, bilet satın alın, bildirimleri itin, her şey hareket halindeyken. Böylece her zaman güncelsiniz. </p>",
      "advertising_image": "/images/smartphone_english.png",
      "duplicate_info_title": "Randevu kopyası",
      "duplicate_info_title_remove": "Lütfen eski randevunuzu iptal edin",
      "duplicate_info_text": "Zaten bir randevu aldınız. Lütfen yeni bir randevu almadan önce eski randevunuzu iptal edin. Eski randevunuzla birlikte size gönderilen e-postayı kontrol ederek eski randevunuzu iptal edebilirsiniz.",
      "slot_already_booked_title": "Randevu alınamıyor",
      "slot_already_booked_text": "Maalesef, istediğiniz tarih bu arada başka biri tarafından rezerve edildi. Verdiğimiz rahatsızlıktan dolayı özür dileriz ve lütfen başka bir randevu seçimi yapar mısınız?"
    },
    "navigation": {
      "cleverq": "Çünkü kim beklemeyi sever?",
      "legal_notice": "baskı",
      "policy": "veri koruması"
    },
    "greetings": {
      "confirm_greetings": "Randevu almaya devam edin"
    },
    "checkout_title": {
      "your": "O",
      "choice": "seçim"
    },
    "enter_invite_code": {
      "verify": "Doğrulamak için",
      "invite_code":"Davet Kodu"
    },
    "appointment_times": {
      "at": "de ",
      "on": "etrafında ",
      "o_clock": "Saat",
      "header": "Ayrılmış randevu saatiniz.",
      "remove": "Yeni bir randevu saati seçin",
      "session": {
        "text": "Oturumunuz şu tarihte sona eriyor: ",
        "close_error_modal": "Kapalı",
        "reservation_expired": "Rezervasyonun süresi doldu, lütfen yeni bir randevu saati seçin.",
        "expired": "Çok uzun süredir aktif olmadığınızdan oturumunuz sona erdi. Lütfen sayfayı yenileyin."
      }
    }
 }
}