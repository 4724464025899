import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import * as actions from '../../actions';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Default } from './elements/Button'
import {scrollToTarget} from "./elements/ScrollToTarget";
import {Modal} from "react-bootstrap";
import { injectIntl } from "react-intl";

class IssueGroups extends Component {
  constructor() {
    super();

    this.state = {
      show_modal: false,
      show_modal_description: false,
      subtask_group_name: null,
      description: null,
      width: 0,
      height: 0,
      active_tooltip: null
    }
  }

  createMarkup(markup) {
    return { __html: markup };
  }
  handleClose() {
    this.setState({ show_modal: false });
  }
  handleCloseDescription() {
    this.setState({ show_modal_description: false, description: null });
  }
  showDescription(name, text) {
    this.setState({ show_modal_description: true, service_name: name, description: text })
  }
  handleClick(subtask_group, e) {
    e.preventDefault();
    this.props.resetAppointmentTimes();
    this.props.selectedSubtaskGroup(subtask_group.id);
    this.props.selectedSubtaskGroupData(subtask_group);
    this.props.setNoIssues(false);
    this.props.handleReset(false);
    this.props.setSubtaskEdit();
    scrollToTarget("scroll_target_subtasks")
  }
  renderIssueGroups() {
    const Button = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      width: 100%;
      text-align: center;
      font-size: 1.1em;
      padding: 12px;
      margin-bottom: 15px;
      border: 1px solid #CBCBCBFF;
      border-radius: ${this.props.site_info.round_buttons ? `${this.props.site_info.round_buttons_value}px` : '3px'};
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    return this.props.selected_service_data.subtask_groups.map(
      subtask_group => {
        if(!subtask_group.group_visible_only_for_desks){
          return (
            <div className={`less-padding ${this.props.selected_service_data.description_html.length ? "col-md-12" : "col-md-6"}`} key={subtask_group.id} style={{display: 'flex', padding: this.props.selected_service_data.description_html.length ? '0px 0px' : ''}}>
              {!this.props.site_info.alternate_buttons ? (
                <Button
                  className={
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }
                  onClick={e => this.handleClick(subtask_group, e)}
                  style={{
                    backgroundColor:
                      this.props.selected_subtask_group === subtask_group.id
                        ? this.props.site_info.button_active
                        : this.props.site_info.button_background,
                    color:
                      this.props.selected_subtask_group === subtask_group.id
                        ? this.props.site_info.button_text_active
                        : this.props.site_info.button_text,
                    width: '100%'
                  }}>
                  <span><div dangerouslySetInnerHTML={this.createMarkup(subtask_group.name)} /></span>
                </Button>
              ) : (
                <div style={{ marginBottom: '15px', width: '100%' }} onClick={e => this.handleClick(subtask_group, e)}>
                  <Default site_info={this.props.site_info} item={subtask_group} selected_item={this.props.selected_subtask_group} icon_type="issues_group"/>
                </div>
              )}
              { subtask_group.description && subtask_group.description.length ?
                <Button
                  onClick={() => this.showDescription(subtask_group.name, subtask_group.description)}
                  className={
                    this.props.site_info.drop_shadows
                      ? 'issue_help drop-shadow-less'
                      : 'issue_help'
                  }
                  style={{
                    fontSize: '1.6rem',
                    width: this.props.site_info.border_on_items ? '55px' : '56px',
                    padding: '0px',
                    marginLeft: '10px',
                    marginTop: this.props.site_info.border_on_items ? '1px' : 0,
                    backgroundColor: this.state.active_tooltip === subtask_group.id ? this.props.site_info.service_tooltip_color : this.props.site_info
                      .service_tooltip_background_color,
                    color: this.state.active_tooltip === subtask_group.id ? this.props.site_info
                      .service_tooltip_background_color : this.props.site_info.service_tooltip_color,
                    border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                    borderRadius: 0,
                    outline: 'none'
                  }}>
                  <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.service_tooltip_icon)} />
                </Button> : null
              }
            </div>
          );
        }
        if(subtask_group.group_visible_only_for_desks && this.props.isUser && !this.props.touchscreen_mode){
          return (
              <div className={this.props.selected_service_data.description_html.length ? "col-md-12" : "col-md-6"} key={subtask_group.id} style={{display: 'flex', padding: this.props.selected_service_data.description_html.length ? '0px 0px' : ''}}>
                {!this.props.site_info.alternate_buttons ? (
                    <Button
                        className={
                          this.props.site_info.drop_shadows ? 'drop-shadow' : null
                        }
                        onClick={e => this.handleClick(subtask_group, e)}
                        style={{
                          backgroundColor:
                              this.props.selected_subtask_group === subtask_group.id
                                  ? this.props.site_info.button_active
                                  : this.props.site_info.button_background,
                          color:
                              this.props.selected_subtask_group === subtask_group.id
                                  ? this.props.site_info.button_text_active
                                  : this.props.site_info.button_text,
                          width: '100%'
                        }}>
                      <span>{subtask_group.name}</span>
                    </Button>
                ) : (
                    <div style={{ marginBottom: '15px', width: '100%' }} onClick={e => this.handleClick(subtask_group, e)}>
                      <Default site_info={this.props.site_info} item={subtask_group} selected_item={this.props.selected_subtask_group} icon_type="issues_group"/>
                    </div>
                )}
                { subtask_group.description && subtask_group.description.length ?
                    <Button
                        onClick={() => this.showDescription(subtask_group.name, subtask_group.description)}
                        className={
                          this.props.site_info.drop_shadows
                              ? 'issue_help drop-shadow-less'
                              : 'issue_help'
                        }
                        style={{
                          fontSize: '1.6rem',
                          width: this.props.site_info.border_on_items ? '55px' : '56px',
                          height: this.props.site_info.border_on_items ? '55px' : '56px',
                          padding: 0,
                          margin: '0 0 0 10px',
                          backgroundColor: this.state.active_tooltip === subtask_group.id ? this.props.site_info.service_tooltip_color : this.props.site_info
                              .service_tooltip_background_color,
                          color: this.state.active_tooltip === subtask_group.id ? this.props.site_info
                              .service_tooltip_background_color : this.props.site_info.service_tooltip_color,
                          border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                          borderRadius: 0,
                          outline: 'none'
                        }}>
                      <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.service_tooltip_icon)} />
                    </Button> : null
                }
              </div>
          );
        }
      }
    );
  }

  render() {
    const subtask_groups_header = this.props.site_info.subtask_groups_header_html || this.props.site_info.subtask_groups_header;
    return (
      <Fragment>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show_modal}
          onHide={this.handleClose.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}}>
            <Modal.Title id="contained-modal-title-lg">
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.selected_service_data.name)} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={this.createMarkup(this.props.selected_service_data.description_html)} />
          </Modal.Body>
        </Modal>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show_modal_description}
          onHide={this.handleCloseDescription.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}} closeButton>
            <Modal.Title id="contained-modal-title-lg">
              <div dangerouslySetInnerHTML={this.createMarkup(this.state.service_name)} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={this.createMarkup(this.state.description)} />
          </Modal.Body>
        </Modal>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        {this.props.selected_service_data &&
        this.props.selected_service_data.subtask_groups.length ? (
          <div
            className={`container animated fadeIn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}>
            <div className="row">
              <div className="col-md-12 issues_header_left no_tab" id="scroll_target_subtask_groups">
                <span className="no_tab" style={this.props.site_info.subtask_groups_header_html ? {fontWeight: "initial"} : {fontWeight: 600}} dangerouslySetInnerHTML={this.createMarkup(subtask_groups_header)}></span>
              </div>
            </div>
            <div
              className="row inline-shadow"
              style={{
                backgroundColor: this.props.site_info.content_background,
                color: this.props.site_info.content_text
              }}>
              <div style={{ marginTop: '15px' }} />
              {this.props.selected_service_data.subtask_groups &&
              this.props.selected_service_data.subtask_groups.length ? (
                <Fragment>
                  <div className={this.props.selected_service_data.description_html.length ? "col-md-6" : "col-md-12"} style={{padding: this.props.selected_service_data.description_html.length ? '' : '0px'}}>
                    {this.props.selected_service_data.subtask_groups
                      ? this.renderIssueGroups()
                      : null}
                  </div>
                  {this.props.selected_service_data.description_html.length ?
                    <div
                      style={{fontSize: '15.4px'}}
                      className={'col-md-6'}
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.props.selected_service_data.description_html
                      )}>
                    </div>
                    : null}
                </Fragment>

              ) : (
                <div className="col-md-6">
                  <ScaleLoader
                    sizeUnit={'px'}
                    size={300}
                    color={this.props.site_info.loading_indicator}
                    loading={true}
                  />
                </div>
              )}
              {!this.props.touchscreen_mode ? (
                <div className="col-md-6">
                  <div
                    style={{ fontSize: '1.1em' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    selected_subtask_group: state.user_selected_subtask_group
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(IssueGroups)
);
