import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import FlagIcon from "react-world-flags";
import { Default, Adac, Adac_header, Lufthansa, Ikk, FinanzamtSH, KreisPaderborn, LaborsAT} from './themes/Header'
import { injectIntl } from "react-intl";
import localeWrapperFlagIcons from "../../helpers/localeWrapperFlagIcons";
import ReactCountryFlag from "react-country-flag";
import LanguageSelector from "../../helpers/LanguageSelector";
import Header from "../content/Header";


const query = new URLSearchParams(window.location.search);

class NavigationTop extends Component {
  onSelectFlag(lang) {
    const queryUrlToken = query.get('url_token')
    const queryServiceId = query.get('service_id')
    const queryHash = query.get('hash')
    const queryTouchscreenMode = query.get('touchscreen_mode')
    const queryEmbeddedMode = query.get('embedded_mode')
    const querySamsungMode = query.get('samsung_mode')
    const queryTouchscreenAccessToken = query.get('touchscreen_access_token')
    window.location.replace(
      `${window.location.origin}/public/sites/${
        this.props.site_info.id
      }/appointments/index.html?lang=${lang}${queryUrlToken ? '&url_token=' + queryUrlToken : ''}${queryServiceId ? '&service_id=' + queryServiceId : ''}${queryHash ? '&hash=' + queryHash : ''}${queryTouchscreenMode ? '&touchscreen_mode=true' : ''}${queryEmbeddedMode ? '&embedded_mode=true' : ''}${querySamsungMode ? '&samsung_mode=true' : ''}${queryTouchscreenAccessToken ? '&touchscreen_access_token=' + queryTouchscreenAccessToken : ''}`
    );
    this.props.setActiveLanguage(lang);
    this.props.localeSet(lang);
  }

  render() {
    const isMobileDevice = window.innerWidth <= 768;
    const {intl} = this.props
    const query = new URLSearchParams(window.location.search);
    const embedded_mode =
      query.get('embedded_mode') === 'true' ? true : false;
    const show_logo =
      query.get('show_logo') === 'true' ? true : false;
    const touchscreen_mode =
      query.get('touchscreen_mode') === 'true' ? true : false;
    const samsung_mode =
      query.get('samsung_mode') === 'true' ? true : false;
    let header_render
    switch (this.props.site_info.custom_identifier) {
      case 1:
        header_render = <Adac site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 2:
        header_render = <Lufthansa site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 3:
        header_render = <Ikk site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 4:
        header_render = <FinanzamtSH site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 5:
        header_render = <KreisPaderborn site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 6:
        // ADAC Württemberg
        header_render = <Adac_header site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 7:
        // ADAC NSA
        header_render = <Adac_header site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      case 8:
        // Labors AT
        header_render = <LaborsAT site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
        break;
      default:
        header_render = <Default site_info={this.props.site_info} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode} isMobileDevice={isMobileDevice} possible_locales={this.props.possible_locales} language={this.props.language}/>
    }
    return this.props.site_info.show_header ? (
        <Fragment>
          { touchscreen_mode && this.props.site_info.custom_identifier !== null ?
              <div>
                <div className={embedded_mode ? "mobile-header" : "mobile-header zindexbooster_wep_page"}>{header_render}</div>
                <div className="header_lang_selector touchscreen_lang_selector">
                  <LanguageSelector
                      possible_locales={this.props.site_info.possible_locales}
                      language={this.props.language}
                      textSize={isMobileDevice ? 13 : 14}
                  />
                </div>
              </div> :
              <div className={this.props.site_info.custom_identifier === null ? embedded_mode ? "mobile-header-default" : "mobile-header-default zindexbooster_wep_page" : embedded_mode ? "mobile-header" : "mobile-header zindexbooster_wep_page"}>{header_render}</div>
          }
        </Fragment>
    ) : (
        touchscreen_mode ?
            <div className="zindexbooster_lang_selector">
              <div className={`header_lang_selector ${show_logo ? 'touchscreen_logo_lang_selector' : 'touchscreen_lang_selector'}`}>
                  <LanguageSelector
                      possible_locales={this.props.site_info.possible_locales}
                      language={this.props.language}
                      textSize={isMobileDevice ? 13 : 14}
                  />
              </div>
            </div>
            :
            <div className="zindexbooster_lang_selector" style={{margin: "0", padding: "0"}}>
              <div
                  className={this.props.logo_align === "right" ? "pull-left zindexbooster_lang_selector " : this.props.logo_align === "left" ? "zindexbooster_lang_selector pull-right" : "zindexbooster_lang_selector pull-right"}
                  style={{
                    paddingRight: `${this.props.logo_align === 'left' || this.props.logo_align === 'center' ? '5px' : '0'}`,
                    width: `${embedded_mode ? '100%' : ''}`,
                    paddingLeft: `${embedded_mode ? '10px' : ''}`
                  }}>
                {!this.props.site_info.show_header && this.props.site_info.show_locale_selector ? (
                    embedded_mode ?
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                          <div>
                            <LanguageSelector
                                possible_locales={this.props.site_info.possible_locales}
                                language={this.props.language}
                                textSize={isMobileDevice ? 13 : 14}
                            />
                          </div>
                        </div>
                        :
                        <div>
                          <LanguageSelector
                              possible_locales={this.props.possible_locales}
                              language={this.props.language}
                              textSize={isMobileDevice ? 13 : 14}
                          />
                        </div>
                ) : null}
              </div>
            </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    selected_language: state.user_selected_language
  };
}

export default injectIntl(
    connect(
        mapStateToProps,
        actions
    )(NavigationTop)
);
